import React, { Component } from 'react';
import WxCodeCmp from '../../components/WxCodeCmp'
import url from '../../util/path'
const fissionList =[{
    title: '搭建微店小程序', 
    content: '无需安装app,集展示、商城、外卖于一体,一键接入微信生态圈,享受微信流量红利，助力打造商家自己的品牌',
    picture: url+'microoperation/charactor_1.png',

},{
    title: '丰富的营销推广方案',
    content: '公众号、小程序互联互通，快速利用拼团、砍价、大转盘等移动社交的火热玩法带动客户的消费热情，加速流量变现。',
    picture: url+'microoperation/character_2.png',

},{
    title:'一体化会员管理体系',
    content:'多渠道互动，打造商家自己的私域流量池，盘活沉睡用户，提高用户活跃度，实现客户留存率的提升。',
    picture: url+'microoperation/character_3.png',
},{
    title:'提供专业的运营服务',
    content:'公众号、小程序互联互通，快速利用拼团、砍价、大转盘等移动社交的火热玩法带动客户的消费热情，加速流量变现。',
    picture: url+'microoperation/character_4.png',
}
]

let Merchant = [{
    title: 'Anikoo美妆',
    content: 'ANikoo美妆主打爆款化妆品和热门产品，致力打造高品质、高价值的购物体验，引领全球美妆护肤新生态',
    componey: '',
    key: '1',
    graphic: url+'home/slide_enterprise_1.png',
    graphic1: require('../../image/home/qa_code1.png')
}, {
    title: '贝特咖啡',
    content: '贝特咖啡坚持如一，始终做一杯有态度的咖啡，感受不一样的甜蜜',
    componey: '',
    key: '2',
    graphic: url+'home/slide_enterprise_2.png',
    graphic1: require('../../image/home/qa_code2.png')
}, {
    title: '维俪肤医疗美容',
    content: '维俪肤医疗美容创立于2016年，是一家专注于皮肤管理、微整注射和整形外科的顶尖医疗整形医院',
    componey: '',
    key: '3',
    graphic: url+'home/slide_enterprise_3.png',
    graphic1: require('../../image/home/qa_code3.png')
}, {
    title: '兔窝镇',
    content: 'BUNNYBURROW(兔窝镇)”是杭州兔窝宠物用品有限公司与香港宠干线有限公司合作在全国建立的小宠物专营店，定位高端，专业致力于进口小宠物培育，互动式营销及互联网新零售品牌',
    componey: '',
    key: '4',
    graphic: url+'home/slide_enterprise_4.png',
    graphic1: require('../../image/home/qa_code4.png')
}, {
    title: '绍美艺术',
    content: '绍美艺术成立于2012年，中心本着“普及、专业、创新、精品”的办学理念，用“专业导入式教学”开启孩子艺术之门，立志打造规模化、专业性、体验性的艺术梦工场',
    componey: '',
    key: '5',
    graphic: url+'home/slide_enterprise_5.png',
    graphic1: require('../../image/home/qa_code5.png')
}, {
    title: '礼正实业',
    content: '杭州礼正实业有限公司作为行业内领先的供应链集成化服务商，专注于协助企业解决从定位，企划，设计，制造，推广，销售，复盘等整体流程的问题',
    componey: '',
    key: '6',
    graphic: url+'home/slide_enterprise_6.png',
    graphic1: require('../../image/home/qa_code6.png')
}]


export default class MicroOperation extends Component {
    constructor(props) {
      super(props)
      this.state = {
        activeIdx: 0
      }
    }
   componentDidMount() {
    this.topRef && this.topRef.current.scrollIntoView();

   }
    render() {
        let { activeIdx } = this.state 
        let { history } = this.props
        return (
          <div className='microOperationPage'>
                <div className="microOperationpage"></div>
                <div className='solution'>

                    <div>

                        <div className='titleBlock'>
                            <text className='title'>精细化运营服务解决方案</text>
                            <div className='line'></div>
                        </div>
                        <div className="solutionImgList">
                          <img className="solutionImg solutionImg1" onClick={() => {
                            if(activeIdx == 1) {
                              this.setState({
                                activeIdx: 0
                              })
                            } else {
                              this.setState({
                                activeIdx: 1
                              })
                            }
                  }} style={activeIdx == 1 ? { width: '7.03rem', height: '5.52rem', zIndex: 9999, transform: "translateZ('100px')" } : {}} src={activeIdx == 1 ? url+'microoperation/solution_big_1.png' :url+'microoperation/solution_small_1.png'} alt="" />
                          <img className="solutionImg solutionImg2" onClick={() => {
                            if(activeIdx == 2) {
                              this.setState({
                                activeIdx: 0
                              })
                            } else {
                              this.setState({
                                activeIdx: 2
                              })
                            }
                  }} style={activeIdx == 2 ? { width: '7.03rem', height: '5.52rem', zIndex: 9999, transform: "translateZ('100px')" } : {}} src={activeIdx == 2 ? url+'microoperation/solution_big_2.png' : url+'microoperation/solution_small_2.png'} alt="" />
                          <img className="solutionImg solutionImg3" onClick={() => {
                            if(activeIdx == 3) {
                              this.setState({
                                activeIdx: 0
                              })
                            } else {
                              this.setState({
                                activeIdx: 3
                              })
                            }
                  }} style={activeIdx == 3 ? { width: '7.03rem', height: '5.52rem', zIndex: 9999, transform: "translateZ('100px')" } : {}} src={activeIdx == 3 ? url+'microoperation/solution_big_3.png' :  url+'microoperation/solution_small_3.png'} alt="" />
                          <img className="solutionImg solutionImg4" onClick={() => {
                            if(activeIdx == 4) {
                              this.setState({
                                activeIdx: 0
                              })
                            } else {
                              this.setState({
                                activeIdx: 4
                              })
                            }
                  }} style={activeIdx == 4 ? { width: '7.03rem', height: '5.52rem', zIndex: 9999, transform: "translateZ('100px')" } : {}} src={activeIdx == 4 ? url+'microoperation/solution_big_4.png' : url+'microoperation/solution_small_4.png'} alt="" />
                        </div>

                    </div>
                    <div>

                       <div className='titleBlock'>
                          <text className='title'>快速打造行业营销新风向</text>
                          <div className='line'></div>
                        </div>
                        <img src={url+'microoperation/flow.png'} alt="" className='direction'/>
                    
                        
                    </div>
                    <div>
                        <div className='titleBlock'>
                            <text className='title'>裂变增长 利用老客户获取新客户</text>
                            <div className='line'></div>
                        </div>
                        <div className='fissionList'>
                            {
                                fissionList.map(element => (
                                    <div className="operationitem">
                                        <img className="picture" src={element.picture} /> 
                                        <div className="title">{element.title}</div>
                                        <div className="content">{element.content}</div>
                                    </div>

                                ))
                            }

                        </div>
                        <div>
                            <div className='titleBlock'>
                                <text className='title'>感恩一路  一路有你</text>
                                <div className='line'></div>
                            </div>


                            <div className='wxcodeWrapList'>
                                {
                                    Merchant.map((element, index) => {
                                        return (
                                            <div style={index == Merchant.length - 1 ? { marginRight: '0.28rem', marginBottom: '0.28rem' } : { marginRight: '0.24rem', marginBottom: '0.24rem'}}>
                                                <WxCodeCmp data={element} key={element.key} isLast={index == Merchant.length - 1 ? true : false} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="moreBtnWrap" onClick={() => {
                          history && history.push('/case')
                        }}>更多案例</div>
                        <div style={{height:'0.30rem'}}></div>

                    </div>
                 </div>
                    

               

                
            </div>

        )
    }

}