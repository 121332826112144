import React from 'react'

export default class TitleCmp extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    let { title } = this.props
    return (
      <div className="title_cmp">
        <div className="word">{title}</div>
        <div className="line"></div>
      </div>
    )
  }
}