import React from 'react'
import { Link } from 'react-router-dom'
const downImg = require('../image/component/Menu/arrow_down.png')
const upImg = require('../image/component/Menu/arrow_up.png')

export default class MenuCmp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      productOpen: true,
      wscOpen: false,
      helpOpen: false,
      aboutOpen: false,
    }
  }
  render() {
    let { productOpen, wscOpen, helpOpen, aboutOpen } = this.state
    let { closeFun } = this.props
    return (
      <div className="menu_cmp">
        <div className="close_wrap" onClick={() => {
          closeFun && closeFun(false)
        }}>
          <img src={require('../image/component/Menu/icon_close.png')} style={{ width: '0.36rem', height: '0.36rem' }} alt="" />
        </div>
        <ul className="first_list" onClick={() => {
          closeFun && closeFun(false, true)
        }}>
          <li className="first_title" style={{ marginBottom: '0.6rem' }}><Link to="/" className="first_title" style={{ paddingRight: '2rem' }}>首页</Link></li>
          <li className="first_title" style={{ marginBottom: '0.6rem' }}>
            <div className="first_item" style={{ marginBottom: '0.42rem' }} onClick={(ev) => {
              ev.preventDefault()
              ev.stopPropagation()
              this.setState({
                productOpen: !productOpen
              })
            }}>
              <span className="first_title">产品</span>
              <img className="arrow_img" src={productOpen ? upImg : downImg} alt="" style={{ marginLeft: '3.33rem' }} />
            </div>
            <ul className="second_list" style={ productOpen ? {} : { display: 'none' }}>
              <li className="secode_title">
                <div className="section_item" style={wscOpen ? { marginBottom: '0.3rem' } : { marginBottom: '0.42rem' }}   onClick={(ev) => {
                    ev.preventDefault()
                    ev.stopPropagation()
                    this.setState({
                      wscOpen: !wscOpen
                    })
                }}>
                  {/* <span className="secode_title">微商城</span> */}
                  <Link to="/weiCommerce" className="secode_title"  onClick={(ev) => {
                    ev.stopPropagation()
                    closeFun && closeFun(false)
                  }}>微商城</Link>
                  <img className="arrow_img" src={wscOpen ? upImg : downImg} alt="" style={{ marginLeft: '2.66rem' }}/>
                </div>
                <ul className="third_list" style={ wscOpen ? {} : { display: 'none' }}>
                  <li style={{ marginBottom: '0.4rem'}}>
                    <Link to="/microMall" className="third_title" style={{ paddingRight: '2rem' }}>微商城</Link>
                  </li>
                  <li style={{ marginBottom: '0.43rem'}}>
                    <Link to="/microStation" className="third_title" style={{ paddingRight: '2rem' }}>微站</Link>
                  </li>
                  <li style={{ marginBottom: '0.51rem'}}>
                    <Link to="/smartStore" className="third_title" style={{ paddingRight: '2rem' }}>智慧门店</Link>
                  </li>
                </ul>
              </li>
              <li className="secode_title section_item" style={{ marginBottom: '0.42rem'}}>
                <Link className="secode_title" to="/microMarketing" style={{ paddingRight: '2rem' }}>微营销</Link>
              </li>
              <li className="secode_title section_item" style={{ marginBottom: '0.42rem'}}>
                <Link className="secode_title" to="/microOperation" style={{ paddingRight: '2rem' }} > 微运营</Link>
              </li>
              <li className="section_item" style={{ marginBottom: '0.42rem'}}>
                <Link className="secode_title" to="/microflow" style={{ paddingRight: '2rem' }}> 微流量</Link>
              </li>
            </ul>
          </li>
          <li className="first_title" style={{ marginBottom: '0.6rem' }}>
            <Link to="/case" className="first_title" style={{ paddingRight: '2rem' }}>经典案例</Link>
          </li>
          {/* <li className="first_title"  style={{ marginBottom: '0.6rem' }}>
            <Link to="/about" className="first_title">关于我们</Link>
          </li> */}
          <li className="first_title" style={{ marginBottom: '0.6rem' }}>
            <div className="first_item"  style={{ marginBottom: '0.42rem' }} onClick={(ev) => {
              ev.preventDefault()
              ev.stopPropagation()
              this.setState({
                helpOpen: !helpOpen
              })
            }}>
              <span>帮助支持</span>
              <img className="arrow_img" src={ helpOpen ? upImg : downImg} alt="" style={{ marginLeft: '2.75rem' }} />
            </div>
            <ul className="second_list" style={ helpOpen ? {} : { display: 'none' }} >
              <li className="secode_title section_item" style={{ marginBottom: '0.42rem'}}>
                <Link to="/helpCenter" className="secode_title" style={{ paddingRight: '2rem' }}>帮助中心</Link>
              </li>
              <li className="secode_title section_item" style={{ marginBottom: '0.42rem' }}><a href="http://p.qiao.baidu.com/cps/chat?siteId=15007660&userId=27346662" target="_blank" style={{ color:'rgba(102,102,102,1)'}}>在线客服</a></li>
              <li className="secode_title section_item" style={{ marginBottom: '0.42rem'}}>
                <Link to="/about" className="secode_title" style={{ paddingRight: '2rem' }}>联系我们</Link>
              </li>
            </ul>
          </li>
          <li className="first_title">
            <div className="first_item"  style={{ marginBottom: '0.42rem' }} onClick={(ev) => {
              ev.preventDefault()
              ev.stopPropagation()
              this.setState({
                aboutOpen: !aboutOpen
              })
            }}>
              <span>关于RockySaaS</span>
              <img className="arrow_img" src={aboutOpen ? upImg : downImg} alt="" style={{ marginLeft: '2.75rem' }} />
            </div>
            <ul className="second_list" style={ aboutOpen ? {} : { display: 'none' }}>
              <li className="secode_title section_item" style={{ marginBottom: '0.42rem'}}>
                <a className="secode_title" href="http://www.rockysaas.com/about" target="_blank"> 企业介绍</a>
              </li>
              {/* <li className="secode_title section_item" style={{ marginBottom: '0.42rem'}}>
                <a className="secode_title" href="https://www.adyun.com/h5/ps_culture.html" target="_blank">企业文化</a>
              </li> */}
              <li className="section_item" style={{ marginBottom: '0.42rem'}}>
                <a className="secode_title" href="http://www.rockysaas.com/productIntroduce" target="_blank">SaaS服务</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    )
  }
}