import React from 'react'

const questionList = [
  'Q1：广告投放原理？',
  'Q2：商+共接入了哪些广告投放系统？',
  'Q3：如何构建商家流量池？',
  'Q4：商家如何对自己的流量池进行差异化运营？',
  'Q5：商家如何将“流量”变“留量”？',
]

export default class SocialActivity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isTop: true,
            showMore: false,
            topDis: '-2.59rem',
            activeIndex: 0
        }
        this.caseScrollHandle = this.caseScrollHandle.bind(this)
    }
    componentDidMount() {
        document.getElementById("root").classList.remove("root_box");
        window.addEventListener('scroll', this.caseScrollHandle);
    }
    componentWillUnmount() {
        document.getElementById("root").classList.add("root_box");
        window.removeEventListener('scroll', this.caseScrollHandle)
    }
    caseScrollHandle() {
        const t = document.documentElement.scrollTop || document.body.scrollTop;
        if (t == 0) {
            this.setState({
                isTop: true
            })
        } else {
            this.setState({
                isTop: false
            })
        }
    }
    render() {
        let { topDis, showMore, isTop,  activeIndex } = this.state
        return (
            <div className="answer_list_page">
                <div className="title">微流量</div>
                {
                    !isTop &&
                    <div className="question_item_wrap">
                        <ul className="question_list">
                          {
                            !showMore && <li className="question_item"><a href={`#flow-question-${activeIndex + 1}`} className="category-item-link">{questionList[activeIndex]}</a></li>
                          }
                          {showMore && 
                            <>
                              {questionList.map((element,index) => {
                                return  <li className="question_item" onClick={() => {
                                  this.setState({
                                    activeIndex: index,
                                    showMore: false,
                                    topDis: '-2.59rem',
                                  })
                                }}><a href={`#flow-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                              })}
                            </>
                          }
                            {/* <li className="question_item"><a href="#flow-question-1" className="category-item-link">Q1：营销插件可以在哪几个行业进行使用？</a></li>
                            {showMore &&
                                <>
                                <li className="question_item"><a href="#flow-question-2" className="category-item-link">Q2：如何使用当面付？</a></li>
                                <li className="question_item"><a href="#flow-question-3" className="category-item-link">Q3：如何使用全民拼团？</a></li>
                                <li className="question_item"><a href="#flow-question-4" className="category-item-link">Q4：如何使用社区版块？</a></li>
                                <li className="question_item"><a href="#flow-question-5" className="category-item-link">Q5：如何设置满包邮？</a></li>
                                <li className="question_item"><a href="#flow-question-6" className="category-item-link">Q6：如何对商品进行推广？</a></li>
                                <li className="question_item"><a href="#flow-question-7" className="category-item-link">Q7：如何创建子店铺？</a></li>
                                <li className="question_item"><a href="#flow-question-8" className="category-item-link">Q8：总店可以为子店铺引流吗？</a></li>

                                </>
                            } */}
                        </ul>
                        <div className="show_more" onClick={() => {
                            this.setState({
                                showMore: !showMore
                            }, () => {
                                let { showMore } = this.state;
                                this.setState({
                                    topDis: showMore ? '-4.75rem' : '-2.59rem'
                                })
                            })
                        }}>{showMore ? '收起更多问题' : '展开更多问题'}</div>
                    </div>
                }
                {
                    isTop &&
                    <ul className="question_list">
                        {/* <li className="question_item"><a href="#flow-question-1" className="category-item-link">Q1：营销插件可以在哪几个行业进行使用？</a></li>
                        <li className="question_item"><a href="#flow-question-2" className="category-item-link">Q2：如何使用当面付？</a></li>
                        <li className="question_item"><a href="#flow-question-3" className="category-item-link">Q3：如何使用全民拼团？</a></li>
                        <li className="question_item"><a href="#flow-question-4" className="category-item-link">Q4：如何使用社区版块？</a></li>
                        <li className="question_item"><a href="#flow-question-5" className="category-item-link">Q5：如何设置满包邮？</a></li>
                        <li className="question_item"><a href="#flow-question-6" className="category-item-link">Q6：如何对商品进行推广？</a></li>
                        <li className="question_item"><a href="#flow-question-7" className="category-item-link">Q7：如何创建子店铺？</a></li>
                        <li className="question_item"><a href="#flow-question-8" className="category-item-link">Q8：总店可以为子店铺引流吗？</a></li> */}
                      {questionList.map((element,index) => {
                        return  <li className="question_item" onClick={() => {
                          this.setState({
                            activeIndex: index,
                            showMore: false,
                            topDis: '-2.59rem',
                          })
                        }}><a href={`#flow-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                      })}
                    </ul>
                }

                <div className="answerList">
                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="flow-question-1" />
                        <div className="solution-title">Q1：广告投放原理？</div>
                        <div className="solution-content">A：商+对接广告商，帮助商家将广告投放到微信公众号、QQ等平台的广告位，帮助商家获取更多流量，提升成交额；在此基础上，依托大数据分析，实现精准投放。</div>

                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="flow-question-2" />
                        <div className="solution-title">Q2：商+共接入了哪些广告投放系统？</div>
                        <div className="solution-content">A：共接入以下三方广告平台：</div>
                        <div className="solution-content">
                            <ul>
                                <li>微信广告（图文底部广告、朋友圈广告、微信大V广告）</li>
                                <li>腾讯智汇推（QQ音乐广告、QQ空间广告、天天快报广告等）</li>
                                <li>头条广告（今日头条、抖音、西瓜视频）</li>
                            </ul>
                        </div>
                        <img className="helper-image" src={require('../../image/answerList/flow-q2-1.jpg')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="flow-question-3" />
                        <div className="solution-title">Q3：如何构建商家流量池？</div>
                        <div className="solution-content">A：商➕通过与多个流量聚集地的对接，帮助商家从各类触点中获取流量数据，沉淀到商家自己的流量池中，进行自动合并及流量标签化管理。</div>

                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="flow-question-4" />
                        <div className="solution-title">Q4：商家如何对自己的流量池进行差异化运营？</div>
                        <div className="solution-content">A：通过公域流量池进入的流量，商家应该对其进行标签化运营，根据性别，年龄，地区的不同进行差异化运营。</div>
                        <img className="helper-image" src={require('../../image/answerList/flow-q4-1.png')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="flow-question-5" />
                        <div className="solution-title">Q5：商家如何将“流量”变“留量”？</div>
                        <div className="solution-content">A：通过对流量池内的用户进行整合，再通过一系列营销活动入营销券、拼团、砍价等将其转化为私域流量，增加店铺复购率。</div>

                    </div>



                </div>


            </div>
        )
    }
}