import React, { PureComponent } from 'react';
import url from '../../util/path'
const marketingData = [
    {
        iconSrc: url+'microflow/character_1.png',
        title: '分析',
        content: '基于受众、品牌、产品的分析得出核心传播点'
    },
    {
        iconSrc: url+'microflow/character_2.png',
        title: '策划',
        content: '策划具有话题性、新闻性、娱乐性、价值性的深度内容'
    },
    {
        iconSrc: url+'microflow/character_3.png',
        title: '整合',
        content: '整合媒体资源传播，达到以小博大的效果'
    },
    {
        iconSrc: url+'microflow/character_4.png',
        title: '私域化',
        content: '结合已有的会员体系通过整合公众号、社群等方式进行会员的管理和流量私域化'
    },
]

export default class Microflow extends PureComponent {
    

    render() {
        return (
            <div className="microflow_page">
                <div className="microflowpage"></div>
                <section className="microflow-container">
                    <div className='titleBlock'>
                        <text className='title'>帮助传统企业在互联网+时代<br/>
                            营销传播如虎添翼</text>
                        <div className='line'></div>
                    </div>
                    <div className="market_list">
                        {marketingData.map((element, index) => {
                            return (
                                <div className="market_item" key={element.title} style={index == 3 ? { marginRight: 0 } : {}}>
                                    <img src={element.iconSrc} alt="" className='marketImg'/>
                                    <div className="market_item_title">{element.title}</div>
                                    <div className="market_item_content">{element.content}</div>
                                </div>
                            )
                        })}
                    </div>
                </section>
                <section className="microflow-container2">
                    <div className='flux'>公域流量智能推广</div>
                    <img src={url+'microflow/popularize_1.png'} className="section2_bg1" alt=""/>
                    <div className='section2_left'>
                        <div className="section2_list"> 
                            <div className="section2_item">
                                <div className="section2_item_title">
                                    微信广告
                                </div>
                                <div className="section2_item_content_list">
                                    <div>图文底部广告</div>
                                    <div>朋友圈广告</div>
                                    <div>微信大V广告</div>
                                </div>
                            </div>
                        </div>
                        <div className="section2_item">
                            <div className="section2_item_title">
                                腾讯智汇推
                            </div>
                            <div className="section2_item_content_list">
                                <div>QQ音乐广告</div>
                                <div>QQ空间广告</div>
                                <div>天天快报广告等</div>
                            </div>
                        </div>
                        <div className="section2_item">
                            <div className="section2_item_title">
                                头条广告
                            </div>
                            <div className="section2_item_content_list">
                                <div>头条</div>
                                <div>抖音</div>
                                <div>西瓜</div>
                            </div>
                        </div>

                    </div>

                </section>
                <section className="microflow-container3">
                    <div className='flux'>私域流量智能推广</div>
                    <img src={url+'microflow/popularize_2.png'} className="section2_bg1" alt="" />
                    <div className='section2_left'>
                        <div className="section2_list">
                            <div className="section2_item">
                                <div className="section2_item_title">
                                    公众号推文
                                </div>
                                <div className="section2_item_content_list">
                                    <div>内容营销 </div>
                                    <div>新品预热</div>
                                </div>
                            </div>
                        </div>
                        <div className="section2_item">
                            <div className="section2_item_title">
                                分销裂变
                            </div>
                            <div className="section2_item_content_list">
                                <div>机制奖励 </div>
                                <div>新品预热</div>
                            </div>
                        </div>
                        <div className="section2_item">
                            <div className="section2_item_title">
                                会员卡机制
                            </div>
                            <div className="section2_item_content_list">
                                <div>签到 </div>
                                <div>积分兑换</div>
                            </div>
                        </div>

                    </div>

                </section>
                <section className='generalize'>
                    <div className='titleBlock'>
                         <text className='title'>推广流程图</text>
                    </div>
                 <img src={url+'microflow/popularize_3.png'} className="generalizeImg" alt="" />

                </section>


            </div>

        )
    }
    
}