import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Sidebar from "react-sidebar";
import MenuCmp from '../components/MenuCmp'

function to(scrollTop){
  window.scrollTo(0, scrollTop);
}

export default class Header extends PureComponent  {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false
        };
        this.scrollTop = 0;
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    onSetSidebarOpen(open, isJump=false) {
        let bodyElem = document.getElementById('body_root')
        if(isJump) {
          bodyElem.classList.remove('dialog-open');
          this.setState({ sidebarOpen: false });
        } else { 
          // 在弹出层显示之前，记录当前的滚动位置
          let scrollTop = window.scrollY;
          if(scrollTop) {
            this.scrollTop = scrollTop
          }
          if(open) { 
            bodyElem.classList.add('dialog-open'); 
            bodyElem.style.top = -scrollTop + 'px';
          } else {
            bodyElem.classList.remove('dialog-open');
            // 滚回到老地方
            to(this.scrollTop);
          }
          this.setState({ sidebarOpen: open });
        }
    }

    portalSideBar = () => {
      let { sidebarOpen } = this.state
      if(sidebarOpen) {
        let side_bar_cmp = (
          <Sidebar
            sidebar={
              <MenuCmp closeFun={this.onSetSidebarOpen} {...this.props} />
            }
            open={this.state.sidebarOpen}
            pullRight={true}
            onSetOpen={this.onSetSidebarOpen}
            styles={{ sidebar: { background: "white" }, root: { zIndex: 9999, transform: 'translateZ(120px)', position: 'fixed' } }}
          >
            <img src={require('../image/component/Header/menu_icon.png')} alt="" className="menu_icon"
              onClick={() => this.onSetSidebarOpen(true)}
            />
          </Sidebar>
        )
       return ReactDOM.createPortal(
          side_bar_cmp,
          document.getElementById('root')
        );
      }

    }

    render() {
        return (
            <div className="header_com" id="header_cmp">

                <img src={require('../image/component/Header/logo.png')} alt="" className="logo"/>

            {/* <Sidebar
                sidebar={
                
                <b>Sidebar content</b>}
                open={this.state.sidebarOpen}
                pullRight={true}
                onSetOpen={this.onSetSidebarOpen}
                styles={{ sidebar: { background: "white" } }}

            >
                    <img src={require('../image/component/Header/menu_icon.png')} alt="" className="menu_icon"
                    onClick={() => this.onSetSidebarOpen(true)}
                    />
                    <button style={{ float: 'right', }}onClick={() => this.onSetSidebarOpen(true)}>
                        <img className="menu_icon" src={require('../image/component/Header/menu_icon.png')}/>
        </button>
            </Sidebar> */}
            <img src={require('../image/component/Header/menu_icon.png')} alt="" className="menu_icon"
              onClick={() => this.onSetSidebarOpen(true)}
            />
            {this.portalSideBar()}
            </div>
        );
    }

}