import React from 'react'

const questionList = [
  'Q1：微信公众号绑定微店有什么作用？',
  'Q2：如何装修店铺？',
  'Q3：如何新建、发布商品？',
  'Q4：支持的支付方式？',
  'Q5：个人中心支持自定义吗？',
  'Q6：登录平台后台后，先进入行业选择界面，四种行业之间有什么区别？',
  'Q7：如何查看最近的订单？',
]

export default class SocialMarketing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isTop: true,
            showMore: false,
            topDis: '-2.59rem',
            activeIndex: 0
        }
        this.caseScrollHandle = this.caseScrollHandle.bind(this)
    }
    componentDidMount() {
        document.getElementById("root").classList.remove("root_box");
        window.addEventListener('scroll', this.caseScrollHandle);
    }
    componentWillUnmount() {
        document.getElementById("root").classList.add("root_box");
        window.removeEventListener('scroll', this.caseScrollHandle)
    }
    caseScrollHandle() {
        const t = document.documentElement.scrollTop || document.body.scrollTop;
        if (t == 0) {
            this.setState({
                isTop: true
            })
        } else {
            this.setState({
                isTop: false
            })
        }
    }
    render() {
        let { topDis, showMore, isTop, activeIndex } = this.state
        return (
            <div className="answer_list_page">
                <div className="title">微营销</div>
                {
                    !isTop &&
                    <div className="question_item_wrap">
                        <ul className="question_list">
                          {
                            !showMore && <li className="question_item"><a href={`#mall-question-${activeIndex + 1}`} className="category-item-link">{questionList[activeIndex]}</a></li>
                          }
                          {showMore && 
                            <>
                              {questionList.map((element,index) => {
                                return  <li className="question_item" onClick={() => {
                                  this.setState({
                                    activeIndex: index,
                                    showMore: false,
                                    topDis: '-2.59rem',
                                  })
                                }}><a href={`#mall-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                              })}
                            </>
                          }
                        </ul>
                        <div className="show_more" onClick={() => {
                            this.setState({
                                showMore: !showMore
                            }, () => {
                                let { showMore } = this.state;
                                this.setState({
                                    topDis: showMore ? '-6.37rem' : '-2.59rem'
                                })
                            })
                        }}>{showMore ? '收起更多问题' : '展开更多问题'}</div>
                    </div>
                }
                {
                    isTop &&
                    <ul className="question_list">
                        {questionList.map((element,index) => {
                          return  <li className="question_item" onClick={() => {
                            this.setState({
                              activeIndex: index,
                              showMore: false,
                              topDis: '-2.59rem',
                            })
                          }}><a href={`#mall-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                        })}
                        {/* <li className="question_item"><a href="#mall-question-1" className="category-item-link">Q1：微信公众号绑定微店有什么作用？</a></li>
                        <li className="question_item"><a href="#mall-question-2" className="category-item-link">Q2：如何装修店铺？</a></li>
                        <li className="question_item"><a href="#mall-question-3" className="category-item-link">Q3：如何新建、发布商品？</a></li>
                        <li className="question_item"><a href="#mall-question-4" className="category-item-link">Q4：支持的支付方式？</a></li>
                        <li className="question_item"><a href="#mall-question-5" className="category-item-link">Q5：个人中心支持自定义吗？</a></li>
                        <li className="question_item"><a href="#mall-question-6" className="category-item-link">Q6：登录平台后台后，先进入行业选择界面，四种行业之间有什么区别？</a></li>
                        <li className="question_item"><a href="#mall-question-7" className="category-item-link">Q7：如何查看最近的订单？</a></li> */}
                        
                    </ul>
                }

                <div className="answerList">
                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="mall-question-1" />
                        <div className="solution-title">Q1：微信公众号绑定微店有什么作用？</div>
                        <div className="solution-content">A：公众号绑定微店后，您可在微店后台设置自定义菜单、自动回复、进行粉丝管理等。公众号将是您店铺面向粉丝的重要出口，您可向粉丝群发推送活动通告、上新通知，和您的粉丝直接的交流和沟通，粉丝可以直接在微信公众号内点击进入您的店铺浏览商品，并完成最终的购买。</div>
                        <img className="helper-image" src={require('../../image/answerList/mall-q1-1.png')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="mall-question-2" />
                        <div className="solution-title">Q2：如何装修店铺？</div>
                        <div className="solution-content">A:进入微享汇http://wd.panshi101.com 后点击店铺模块，微店提供多种模板供用户选择，用户也可自行diy编辑，点击“编辑”模块即可进入编辑页面。在本页面可以使用各个功能组件和微页面搭建小程序官网首页的内容。</div>
                        <img className="helper-image" src={require('../../image/answerList/mall-q2-1.png')} />
                        <img className="helper-image" src={require('../../image/answerList/mall-q2-2.png')} />
                        <div className="solution-content">组件库：</div>
                        <div className="solution-content">
                            <ul>
                                <li>• 图文列表：用于以列表形式展示内容的组件，需要上传小图标和设置标题，可配置跳转；</li>
                                <li>• 轮播图：通过控件上传广告图，当上图多张图片时，会轮播展示，建议宽度为 640。上传图片后还可配置链接到商品分类或某个商品；</li>
                                <li>• 导航图：一般用于分类索引，4 个一组，通过上传小图标并链接到商品分类或某个商品，每张图片建议尺寸140*140px；</li>
                                <li>• 二格框：每 2 张图片及标题一组，上传图片后可索引链接到商品分类或某个商品等，每张图片建议尺寸210*210px；</li>
                                <li>• 三格框：每 3 张图片一组，上传图片后可索引链接到商品分类或某个商品等，每张图片建议尺寸 210*210px；</li>
                                <li>• 多图片组件：多图片组件支持添加多个图片，可以设置两种展示形式：横向滑动和多行展示，上传图片后可索引链接到商品分类或某个商品等，每张图片建议尺寸290*290px；</li>
                                <li>• 富文本：多功能集于一身的控件，可用于添加版本多种信息，如文字、图片、项目</li>
                                <li>• 标识、排版、表格、底色、文字大小及颜色等；</li>
                                <li>• 视频：可以插入视频展示的组件，需要注意填写的视频链接需要是视频文件的地址;</li>
                                <li>• 背景音乐：可上传背景音乐，上传后访问小程序首页会自动播放，可以点击音乐图标停止播放。</li>
                                <li>• 公告：可增加公告，支持上传多个公告和编辑样式（轮播形式、图标样式）以及设置链接页面地址；</li>
                                <li>• 资讯：可添加链接到不同的微页面分组，实现资讯文章的呈现。还可对组件的样式（包括导航文字的间距、颜色以及背景）进行调整；</li>
                                <li>• 橱窗：以一大两小展示的组件</li>
                                <li>• 标题：主要用于添加标题，对后续一类内容的注释，可在主标题下添加副标题，对标题排版居左、居中、居右、添加底色，并对标题链接到商品分类或某一个商品，支持组件样式自定义；</li>
                                <li>• 文本导航：纯文字的导航组件，支持组件样式自定义</li>
                                <li>• 辅助线：添加虚线辅助线，可用于分隔不同栏目效果；</li>
                                <li>• 辅助空白：添加空白辅助小横条模块，可用于分隔不同栏目；</li>
                                <li>• 底部菜单：恒置于页面底部的菜单组件</li>
                                <li>• 客服：浮动于页面右下角的客服组件，客服组件使用微信小程序自带功能，需要进入小程序后台配置客服人员（具体配置如有疑问见旧系统操作文档客服配置步骤）</li>
                                <li>• 微页面组件：使用“微页面”组件，可使用该组件批量展示公司案例、资讯、新闻等信息</li>
                                <li>• 推广图组件：推广图可引导用户在不同主体小程序间的跳转；建议在推广图上传小程序码，提示信息填写具备引导文字，如”长按识别图中的小程序码“、“发送给好友”或”保存到手机转发朋友圈“等，实现传播导流的目的。</li>
                                <li>• 自由组件：自由组件可以自由设置组件中的文本、图片和按钮的位置和尺寸，可用于搭建多种灵活的小程序样式，可以将文本、图片和按钮等带星号的组件拖到自由组件中使用，支持组件样式自定义</li>
                            </ul>
                        </div>
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="mall-question-3" />
                        <div className="solution-title">Q3： 如何新建、发布商品？</div>
                        <img className="helper-image" src={require('../../image/answerList/mall-q3-1.png')} />
                        <img className="helper-image" src={require('../../image/answerList/mall-q3-2.png')} />
                        <div className="solution-content">上传发布新商品，填写商品基本信息与商品详情；</div>
                        <div className="solution-content">
                            <ul>
                                <li>• 基本信息：包含商品名称、价格、图片、商品主图视频、商品分组、库存信息、商品规格、运费物流等；</li>
                                <li>• 商品详情：商家可通过商品、图片广告、图片导航、三格框、四格框、标题、商品搜索、辅助线、辅助空白、富文本控件进行制作</li>
                                <li>• 网页商品导入：支持导入天猫商品，即商家可从天猫商城通过复制商品链接的方式导入商品编辑页中，可获取到商品图片和详情图片，便于商家快速创建商品。导入前需点击右上角“商品导入配置”设置 API KEY</li>
                                <li>• 商品信息：包含名称、价格、原价、虚拟销量、商品图、略缩图以及商品分组和序号</li>
                                <li>• 库存/规格：可选择启用商品规格以及输入总库存、商品单位、商品编码以及扫码购编码（输入商品条形码下方的数字，可结合线下零售场景）</li>
                                <li>• 物流：可选择对应的运费设置，包含包邮、统一邮费以及运费模板收费</li>
                                <li>• 高级设置：可启用城市定位、积分抵扣，自定义底部购买按钮文字，还可启用关联表单，让买家直接提交表单，用于定制或预售等场景。此外还可开启会员限购，客户需拥有选定的会员卡才能购买该商品。</li>
                            </ul>
                        </div>

                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="mall-question-4" />
                        <div className="solution-title">Q4：支持的支付方式？</div>
                        <div className="solution-content">针对所有行业，新增货到付款、到店支付；</div>
                        <div className="solution-content">
                            <ul>
                                <li>• 货到付款：启用后买家可在下单时选择货到付款，需自行通过合作快递安排配送。买家开箱验货无误后，配送方向买家收款，并与商家结算费用；</li>
                                <li>• 到店支付：启用后买家可在下单时选择到店支付，需自行到达商家指定地点提货，支付订单费用。</li>
                                <li>• 微信支付：微配置小程序微信支付商户信息才能使用；</li>
                            </ul>
                        </div>
                        <img className="helper-image" src={require('../../image/answerList/mall-q4-1.png')} />
                        <img className="helper-image" src={require('../../image/answerList/mall-q4-2.jpg')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="mall-question-5" />
                        <div className="solution-title">Q5：个人中心支持自定义吗？</div>
                        <div className="solution-content">A：在“店铺”——“个人中心”，可设置个人中心的展示内容，商家可以自定义个人中心页面的组件及背景图。</div>
                        <div className="solution-content">组件设置：在店铺-->个人中心-->组件设置中，可勾选是否开启组件功能，并且可通过输入框修改组件标题，还可上传组件的图片以及修改组件标题的颜色。</div>
                        <img className="helper-image" src={require('../../image/answerList/mall-q5-1.png')} />
                        <div className="solution-content">背景设置：在店铺-->个人中心-->背景设置中，可勾选是否开启组件功能，并且可通过输入框修改组件标题，还可上传组件的图片以及修改组件标题的颜色。</div>
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="mall-question-6" />
                        <div className="solution-title">Q6：登录平台后台后，先进入行业选择界面，四种行业之间有什么区别？</div>
                        <div className="solution-content">问题答案这里开始开店必要公众号为什么要公众</div>
                        <div className="solution-content">
                            <ul>
                                <li>• 官网小程序：适用于小程序品牌传播、咨询展示等场景</li>
                                <li>• 商城小程序：适用于小程序在线开店、客户管理等场景</li>
                                <li>• 外卖零售小程序：适用于小程序餐饮、水果、零售、便利店等场景</li>
                                <li>• 预约小程序：适用于小程序预约服务、上门到店等场景</li>
                            </ul>
                        </div>
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="mall-question-7" />
                        <div className="solution-title">Q7：如何查看最近的订单？</div>
                        <div className="solution-content">A：平台客户查看及管理订单的模块，列表显示订单概要信息，包含商品信息、收货信息等；订单状态分为待付款、待发货、已发货、交易完成、已取消、退款中、已退款；</div>
                        <div className="solution-content">
                            <ul>
                                <li>• 已取消：买家手动取消或超时被自动取消的订单，可在“订单设置”模块修改自动取消订单时间；</li>
                                <li>• 退款中：买家申请退货退款的订单，商家需定时查看以及及时处理；</li>
                                <li>• 已退款：商家同意退款的订单，同意退款后订单金额原路退还粉丝支付账号，如果粉丝微信支付时选择了银行卡支付，一般退款后需要1~4 天到账；</li>
                                <li>• 待发货：买家已支付待商家发货的订单，商家需定时查看以便及时进行发货，支持有物流发货、无物流发货，无物流发物不需要输入快递信息；</li>
                                <li>• 查询：支持通过订单号、商品名称、收货人姓名/电话、下单时间进行查询；</li>
                                <li>• 批量导出：支持将不同查询条件下的订单导出excel 表格，方便核对或发货；</li>
                                <li>• 批量发货：支持将批量导出的 excel 表格填写好快递信息后上传，实现自动批量发货。</li>
                                <li>• 发货：对待发货的订单进行发货操作</li>
                                <li>• 修改物流：对已发货的订单的物流信息进行修改</li>
                                <li>• 修改价格：对待支付的订单需要支付的金额进行修改</li>
                                <li>• 退货：对申请退货的订单进行退款操作</li>
                                <li>• 同意退款：同意客户发起的部分退款申请</li>
                                <li>• 拒绝退款：拒绝客户发起的部分退款申请</li>
                                <li>• 打印订单：打印该订单，用电脑连接打印机即可打印</li>

                            </ul>
                        </div>
                        <img className="helper-image" src={require('../../image/answerList/mall-q7-1.png')} />
                    </div>
                </div>
            </div>
        )
    }
}