import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Carousel , Button, Modal } from 'antd-mobile';
import ClickPop from '../../components/ClickPop';
import url from '../../util/path'
const img1 = url+'microshop/header.png'
const img2 = url+'microshop/smartshop/header.png'
const img3 = url+'microshop/header_site.png'

export default class WeiCommerce extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [img1, img2, img3],
      imgUrl: ['/microMall', '/smartStore', '/microStation'],
      imgHeight: 176,
      visible: false
    }
    this.onClose = this.onClose.bind(this)
  }
    onClose = () => {
      this.setState({
          visible: false
      })
    }

    render() {
      let { visible, imgUrl } = this.state
        return (

            <div className="weiCommercePage">
            <Carousel
                
                autoplay={true}
                infinite
                beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
                afterChange={index => console.log('slide to', index)}
            >
                {this.state.data.map((val,index) => (
                    <Link
                      to={imgUrl[index]}
                        key={val}
                        style={{ display: 'inline-block', width: '100%', height: this.state.imgHeight }}
                    >
                        <img
                            src={val}
                            alt=""
                            style={{ width: '100%', verticalAlign: 'top' }}
                            // onLoad={() => {
                            //     // fire window resize event to change height
                            //     window.dispatchEvent(new Event('resize'));
                            //     this.setState({ imgHeight: 'auto' });
                            // }}
                        />
                    </Link>
                ))}
            </Carousel>
                <div className='solution'>
                    <div className='titleBlock'>
                        <text className='title'>我们的电商化解决方案</text>
                        <div className='line'></div>
                    </div>
                    <div className='content'>
                        <div className='card_canves'>
                            <div className='content_title'>微商城</div>
                            <div className='content_list'>
                                <div className='content_list_section'>• 快速搭建移动商城</div>
                                <div className='content_list_section'>• 多种营销玩法</div>
                                <div className='content_list_section'>• 多流量渠道获客</div>
                                <div>• 分销拓展销售渠道</div>
                            </div>
                        </div>
                        <img src={url+'microshop/main_1.png'} alt="" className='showImg'/>

                    </div>
                    <div className='content1'>
                        <img src={url+'microshop/main_2.png'} alt="" className='showImg1' />
                        <div className='card_canves'>
                            <div className='content_title2'>智慧门店</div>
                            <div className='content_list2'>
                                <div className='content_list_section2'>• 门店网店互通化</div>
                                <div className='content_list_section2'>• 仓店一体化</div>
                                <div className='content_list_section2'>• 智能导购一体化</div>
                                <div>• 数字会员管理化</div>
                            </div>
                        </div>

                    </div>
                    <div className='content'>
                        <div className='card_canves'>
                            <div className='content_title'>微站</div>
                            <div className='content_list'>
                                <div className='content_list_section'>• 操作简单，自由拖拽建站</div>
                                <div className='content_list_section'>• 小程序流量入口</div>
                                <div className='content_list_section'>• 企业品牌形象展示</div>
                                <div>• 实时互动，实现高转化率</div>
                            </div>
                        </div>
                        <img src={url+'microshop/main_3.png'} alt="" className='showImg' />

                    </div>
                    <div className='scheme'>

                        <div className='titleBlock'>
                            <text className='title'>覆盖全场景的电商解决方案</text>
                            <div className='line'></div>
                        </div>
                        <div className="poker-table">
                            <div className='pokerMall solutionItem'>
                                <img src={url+'microshop/solution_1.png'} alt="" className='pokerImg'/>
                                <div className="btn-box" style={{ background: '#FFEEDD', color: '#FF7121'}}                 
                                onClick={() => {
                                    this.setState({ visible:true})
                                }}>
                                  开始开店
                                    {/* <Button ><a className="ant-btn-link" target="blank">开始开店</a></Button> */}
                                </div>
                            </div>
                            <div className="pokerMall2 solutionItem">
                                <img src={url+'microshop/solution_2.png'} alt="" className='pokerImg' />
                                <div className="btn-box" style={{ background: '#E2F3FD', color: '#348EF4'}}                 
                                onClick={() => {
                                    this.setState({ visible:true})
                                }}>
                                  开始开店
                                    {/* <Button ><a className="ant-btn-link" target="blank">开始开店</a></Button> */}
                                </div>
                            </div>
                            <div className='pokerMall solutionItem'>
                                <img src={url+'microshop/solution_3.png'} alt="" className='pokerImg'/>
                                <div className="btn-box" style={{ background: '#E5F8F3', color: '#2AB794'}}                 
                                onClick={() => {
                                    this.setState({ visible:true})
                                }}>
                                  开始开店
                                    {/* <Button ><a className="ant-btn-link" target="blank">开始开店</a></Button> */}
                                </div>
                            </div>
                            <div className="pokerMall2 solutionItem">
                                <img src={url+'microshop/solution_4.png'} alt="" className='pokerImg' />
                                <div className="btn-box" style={{ background: '#EDEAFF', color: '#8375DC'}}                       
                                onClick={() => {
                                    this.setState({ visible:true})
                                }}>
                                  开始开店
                                {/* <Button ><a className="ant-btn-link"  target="blank">开始开店</a></Button> */}
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='service'>

                        <div className='titleBlock'>
                            <text className='title'>业内最贴心的一站式服务</text>
                            <div className='line'></div>
                        </div>
                        <div className='poker-table'>
                            
                            <img src={url+'microshop/service_1.png'} alt="" className='pokerImg2' />
                            <img src={url+'microshop/service_2.png'} alt="" className='pokerImg2' />
                            <img src={url+'microshop/service_3.png'} alt="" className='pokerImg2' />
                            <img src={url+'microshop/service_4.png'} alt="" className='pokerImg2' />
                           

                        </div>


                    </div>
                    <div className='partner'>
                        <div className='titleBlock'>
                            <text className='title'>合作伙伴</text>
                            <div className='line'></div>
                        </div>
                        <div className='partner_img'>
                            <img src={url+'microshop/partner_1png.png'} alt="" className='pokerImg3'/>
                            <img src={url+'microshop/partner_2.png'} alt="" className='pokerImg3' />
                            <img src={url+'microshop/partner_3.png'} alt="" className='pokerImg3' />
                            <img src={url+'microshop/partner_4.png'} alt="" className='pokerImg3' />
                            <img src={url+'microshop/partner_5.png'} alt="" className='pokerImg3' />
                            <img src={url+'microshop/partner_6.png'} alt="" className='pokerImg3' />
                            <img src={url+'microshop/partner_7.png'} alt="" className='pokerImg3' />
                            <img src={url+'microshop/partner_8.png'} alt="" className='pokerImg3' />


                        </div>


                    </div>
                </div>
                <Modal 
                    visible={visible}
                    transparent
                    className= "modal"
                    wrapClassName="modal"
                    maskClosable={true}
                    onClose={this.onClose}
                    // title="Title"
                    // footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}
                    // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                    // afterClose={() => { alert('afterClose'); }}
                >
                    <ClickPop closeFun={this.onClose} /> 
                </Modal>
                
            </div>

        );
    }

}