import React from "react";
import logo from "./logo.svg";
// import DatePicker from 'antd-mobile/lib/date-picker';  // 加载 JS
// import 'antd-mobile/lib/date-picker/style/css';        // 加载 CSS
// import 'antd-mobile/lib/date-picker/style';         // 加载 LESS
import Header from './components/Header';
import Footer from './components/Footer';

import Home from "./pages/home/index";
import WeiCommerce from "./pages/weiCommerce/index";
import MicroMarketing from "./pages/microMarketing/index";
import MicroOperation from "./pages/microOperation/index";
import Microflow from "./pages/microflow/index";
import MicroMall from './pages/microMall/index';
import About from "./pages/about/index";
import Case from "./pages/case/index";
import HelpCenter from "./pages/helpCenter/index";
import MicroStation from './pages/microStation/index';
import SmartStore from './pages/smartStore/index';
import AnswerListOne from './pages/answerList/AnswerListOne';
import SocialMarketing from './pages/answerList/SocialMarketing';
import SocialOperation from './pages/answerList/SocialOperation';
import SocialActivity from './pages/answerList/SocialActivity';
import SocialFlow from './pages/answerList/SocialFlow';
import "antd-mobile/dist/antd-mobile.css";
import './css/index.scss'
// import routerConfig from './router/routerConfig'; 





import {BrowserRouter as Router,Switch, Route,Link, Redirect} from "react-router-dom";
// import { createHashHistory, createMemoryHistory, createBrowserHistory } from 'history';
    


class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
       bigImg: false,
       bigImg2: false,
    };
    this.onClosebigImg = this.onClosebigImg.bind(this)
  }
  onClosebigImg = ( aoa) => {
    // console.log('aoa', aoa)
    if(aoa == 'bigImg') {
      this.setState({
        bigImg: true,
        bigImg2: false
      })
    } else {
      this.setState({
        bigImg: false,
        bigImg2: true
      })
    }

  }
  render() {
    // console.log('createBrowserHistory', createBrowserHistory)
      return (
        <Router>
          <div onClick={() => {
            this.setState({ bigImg: false, bigImg2: false})
            // console.log('接收到点击了')
          }}>
            <Header></Header>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/weiCommerce" render={(props) => {
                return <WeiCommerce {...props} />
              }} />
              <Route exact path="/microMarketing" render={(props) => {
                return <MicroMarketing {...props} />
              }} />
              <Route exact path="/microOperation" component={MicroOperation} />
              <Route exact path="/microflow" render={(props) => {
                return <Microflow {...props} />
              }} />
              <Route exact path="/about" render={(props) => {
                return <About {...props} />
              }} />
              <Route exact path="/case" render={(props) => {
                return <Case {...props} />
              }} />
              <Route exact path="/helpCenter" component={HelpCenter} />
              <Route exact path="/microMall" render={(props) => {
                return <MicroMall {...props}/>
              }} />
      
              <Route exact path="/microStation"render={(props) => {
                return <MicroStation {...props} />
              }} />
              <Route exact path="/smartStore" render={(props) => {
                return <SmartStore {...props} />
              }} />
              <Route exact path="/answerListOne" component={AnswerListOne} />
              <Route exact path="/SocialMarketing" component={SocialMarketing}/>
              <Route exact path="/SocialOperation" component={SocialOperation} />
              <Route exact path="/SocialActivity"  component={SocialActivity} />
              <Route exact path="/SocialFlow" component={SocialFlow} />

              <Redirect to="/" />
            </Switch>
            <Footer onClosebigImg={this.onClosebigImg} {...this.state}  ></Footer>
          </div>
        </Router>



  );
  }
}


export default App;
