import React from 'react'

const questionList = [
  'Q1：营销插件可以在哪几个行业进行使用？',
  'Q2：如何使用当面付？',
  'Q3：如何使用全民拼团？',
  'Q4：如何使用社区版块？',
  'Q5：如何设置满包邮？',
  'Q6：如何对商品进行推广？',
  'Q7：如何创建子店铺？',
  'Q8：总店可以为子店铺引流吗？'
]

export default class SocialActivity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isTop: true,
            showMore: false,
            topDis: '-2.59rem',
            activeIndex: 0
        }
        this.caseScrollHandle = this.caseScrollHandle.bind(this)
    }
    componentDidMount() {
        document.getElementById("root").classList.remove("root_box");
        window.addEventListener('scroll', this.caseScrollHandle);
    }
    componentWillUnmount() {
        document.getElementById("root").classList.add("root_box");
        window.removeEventListener('scroll', this.caseScrollHandle)
    }
    caseScrollHandle() {
        const t = document.documentElement.scrollTop || document.body.scrollTop;
        if (t == 0) {
            this.setState({
                isTop: true
            })
        } else {
            this.setState({
                isTop: false
            })
        }
    }
    render() {
        let { topDis, showMore, isTop, activeIndex } = this.state
        return (
            <div className="answer_list_page">
                <div className="title">微商城</div>
                {
                    !isTop &&
                    <div className="question_item_wrap">
                        <ul className="question_list">               
                          {
                            !showMore && <li className="question_item"><a href={`#marketing-question-${activeIndex + 1}`} className="category-item-link">{questionList[activeIndex]}</a></li>
                          }
                          {showMore && 
                            <>
                              {questionList.map((element,index) => {
                                return  <li className="question_item" onClick={() => {
                                  this.setState({
                                    activeIndex: index,
                                    showMore: false,
                                    topDis: '-2.59rem',
                                  })
                                }}><a href={`#marketing-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                              })}
                            </>
                          }
                        </ul>
                        <div className="show_more" onClick={() => {
                            this.setState({
                                showMore: !showMore
                            }, () => {
                                let { showMore } = this.state;
                                this.setState({
                                    topDis: showMore ? '-6.37rem' : '-2.59rem'
                                })
                            })
                        }}>{showMore ? '收起更多问题' : '展开更多问题'}</div>
                    </div>
                }
                {
                    isTop &&
                    <ul className="question_list">
                      {questionList.map((element,index) => {
                        return  <li className="question_item" onClick={() => {
                          this.setState({
                            activeIndex: index,
                            showMore: false,
                            topDis: '-2.59rem',
                          })
                        }}><a href={`#marketing-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                      })}
                        {/* <li className="question_item"><a href="#marketing-question-1" className="category-item-link">Q1：营销插件可以在哪几个行业进行使用？</a></li>
                        <li className="question_item"><a href="#marketing-question-2" className="category-item-link">Q2：如何使用当面付？</a></li>
                        <li className="question_item"><a href="#marketing-question-3" className="category-item-link">Q3：如何使用全民拼团？</a></li>
                        <li className="question_item"><a href="#marketing-question-4" className="category-item-link">Q4：如何使用社区版块？</a></li>
                        <li className="question_item"><a href="#marketing-question-5" className="category-item-link">Q5：如何设置满包邮？</a></li>
                        <li className="question_item"><a href="#marketing-question-6" className="category-item-link">Q6：如何对商品进行推广？</a></li>
                        <li className="question_item"><a href="#marketing-question-7" className="category-item-link">Q7：如何创建子店铺？</a></li>
                        <li className="question_item"><a href="#marketing-question-8" className="category-item-link">Q8：总店可以为子店铺引流吗？</a></li> */}

                    </ul>
                }

                <div className="answerList">
                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-1" />
                        <div className="solution-title">Q1：营销插件可以在哪几个行业进行使用？</div>
                        <div className="solution-content">A：平台提供多种营销插件供平台客户使用，但营销插件仅在商城、外卖和预约行业开放。包括优惠券、全民拼团、疯狂砍价、秒杀活动、大转盘、砸金蛋等一系列营销活动。</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q1-1.png')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-2" />
                        <div className="solution-title">Q2：如何使用当面付？</div>
                        <div className="solution-content">A：通过当面付可以创建收款码，将收款码打印布置在门店中，可以用于线下收款。买家扫码打开小程序，输入消费金额支付。在当面付收款码管理中，可以对小程序中拼团活动进行新建、修改和删除等操作。需要注意：创建收款码必须先授权小程序到平台平台，配置好小程序支付，并发布小程序。</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q2-1.png')} />
                        <div className="solution-content">当发布新的收款码，填写收款码基本信息；</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q2-2.png')} />
                        <div className="solution-content">
                            <ul>
                                <li>• 收款码名称：填写收款码的名称</li>
                                <li>• 收款码 LOGO：上传收款码的LOGO，将显示在收款界面中</li>
                                <li>• 是否可用优惠券：设置收款码是否可以使用优惠券，选择不可用，在付款时将不能选择优惠券使用。</li>
                            </ul>
                        </div>

                        <div className="solution-content">创建付款码后，需要下载收款码并打印布置在店中，方便消费者扫码付款。也可以在小程序中配置付款码入口，通过小程序进入线下付款。</div>
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-3" />
                        <div className="solution-title">Q3：如何使用全民拼团？</div>
                        <div className="solution-content">A：通过拼团可以吸引买家进行分享，吸引更多人流进入小程序进行消费。需要注意拼团活动不可使用优惠券。在全民拼团管理中，可以对小程序中拼团活动进行新建和修改操作。</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q3-1.png')} />
                        <div className="solution-content">发布新的全民拼团活动，填写拼团活动基本信息；</div>
                        <div className="solution-content">
                            <ul>
                                <li>• 活动名称：填写拼团活动的名称</li>
                                <li>• 活动时间：填写拼团活动的持续时间</li>
                                <li>• 拼团限制：限制拼团活动中，每人参团的次数（对同一人开团仅能参团一次）</li>
                                <li>• 库存预留时间：拼团成功后，商品将会预留库存，超时后，将不再预留库存</li>
                                <li>• 参与人数：拼团活动首页展示的参与人数</li>
                                <li>• 首页轮播图：拼团活动首页的广告图</li>
                                <li>• 活动规则：拼团活动的活动规则文案</li>
                                <li>• 分享形式：拼团活动的分享形式</li>
                                <li><img className="helper-image" src={require('../../image/answerList/marketing-q3-2.png')} /></li>
                                <li>• 拼团商品设置：拼团活动的商品既可以从商品列表中选择，也可以自行设置商品信息，支持设置多个拼团商品</li>
                                <li>• 拼团价：成团后，开团和参团人均可以此价格购买本商品，如果设置为 0，则无需支付直接领取</li>
                                <li>• 成团人数：成团需要的人数，包括开团人</li>
                                <li>• 每人限购：此商品的限购数量，达到限购数量后将无法开团和参团</li>
                                <li>• 创建拼团活动后，需要在小程序装修时配置对应的拼团活动入口，方便用户参与活动。</li>

                            </ul>
                        </div>
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-4" />
                        <div className="solution-title">Q4：如何使用社区版块？</div>
                        <div className="solution-content">A：在后台-->营销-->社区管理页面，可以创建并管理多个社区版块；</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q4-1.png')} />
                        <div className="solution-content">通过版块管理可以对社区中的版块进行管理。</div>
                        <div className="solution-content">
                            <ul>
                                <li>• 新建版块：点击添加新的社区版块</li>
                                <li>• 管理话题：点击可对该版块下的话题进行管理</li>
                                <li>• 管理用户：点击可对该板块下的用户进行管理（禁言）</li>
                                <li>• 编辑：编辑该板块信息</li>
                                <li>• 删除：可删除该版块</li>


                            </ul>
                        </div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q4-2.png')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-5" />
                        <div className="solution-title">Q5：如何设置满包邮？</div>
                        <div className="solution-content">A：满包邮支持两种规则，买家下单时，满足满包邮条件享受包邮优惠，如果两者均开启，只要满足其中一个即可享受包邮优惠：</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q5-1.png')} />
                        <div className="solution-content">
                            <ul>
                                <li>• 购物满 X 件包邮</li>
                                <li>• 购物满 X 元包邮</li>
                            </ul>
                        </div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q5-2.png')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-6" />
                        <div className="solution-title">Q6：如何对商品进行推广？</div>
                        <div className="solution-content">A：电商商城里面所有商品均可生成商品推广海报，可以将商品推广海报直接分享到朋友圈或者分享给好友。好友长按识别海报上的小程序码即可进入小程序购买此商品，该营销功能只对电商产品生效。</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q6-1.png')} />
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-7" />
                        <div className="solution-title">Q7：如何创建子店铺？</div>
                        <div className="solution-content">A：通过多门店管理，可以为名下的分店创建多个子店账号，每个子店可以独立装修自己的店铺，配置商品以及管理订单。总店商户可以在店铺列表中管理自己的子店</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q7-1.png')} />
                        <div className="solution-content">
                            <ul>
                                <li>• 新建店铺：创建新的子店铺</li>
                                <li>• 登录帐号&amp;密码：子店登录后台的账号和密码</li>
                                <li>• 主营项目：子店经营的产品类型</li>
                                <li>• 店铺分类：子店所属的店铺分类，从已经创建好的店铺分类中选择</li>
                                <li>• 店铺名称&amp;简介：子店的名称和简介</li>
                                <li>• 店铺图片：子店的 logo 图片</li>
                                <li>• 抽出比例：总店从子店的交易金额中获得的抽成比例。</li>
                                <li>• 联系方式：子店联系人的姓名和电话</li>
                                <li>• 营业时间：子店的营业时间</li>
                                <li>• 是否营业：控制子店是否营业，不营业的子店将不会显示</li>
                                <li>• 排序号：控制子店在列表中的排序</li>
                                <li>• 联系地址：子店的联系地址</li>
                            </ul>
                        </div>
                    </div>

                    <div className="solution-item">
                        <div style={{ position: 'absolute', top: topDis }} id="marketing-question-8" />
                        <div className="solution-title">Q8：总店可以为子店铺引流吗？</div>
                        <div className="solution-content">A：在对总店店铺进行装修时，可以添加多门店组件，多门店组件可以展示某一个分类或者所有的子店，故多门店组建的配置可以使用户通过访问总店再进入到访各个子店。</div>
                        <img className="helper-image" src={require('../../image/answerList/marketing-q8-1.png')} />
                        <img className="helper-image" src={require('../../image/answerList/marketing-q8-2.png')} />

                    </div>
                </div>
            </div>
        )
    }
}
