import React from 'react'
import TitleCmp from '../../components/TitleCmp'
import url from '../../util/path'
const advantageData = [
  {
    imgSrc: url+'microshop/microshop/perspective_1.png',
    title: '获客',
    desc: '微信生态60+超级流量入口，帮助商家快速获客'
  },
  {
    imgSrc: url+'microshop/microshop/perspective_2.png',
    title: '转化',
    desc: '完善的系统工具，更注重消费者体验，促进转化'
  },
  {
    imgSrc: url+'microshop/microshop/perspective_3.png',
    title: '裂变',
    desc: '拼团、分销、砍价等营销玩法，实现裂变传播'
  },
  {
    imgSrc: url+'microshop/microshop/perspective_4.png',
    title: '复购',
    desc: '数字化会员管理，助力商家轻松提升复购增购'
  },
  {
    imgSrc: url+'microshop/microshop/perspective_5.png',
    title: '留存',
    desc: '精准的用户画像分析，帮更多商家汇聚私域流量'
  }
]

const serviceData = [
  {
    imgSrc:  url+'microshop/microshop/provide_1.png',
    title: '多样化的店铺装修风格',
    desc: ['一键应用', '百余套精美行业模版', '组件自由拖拽组合装修', '满足您的个性化需求']
  },
  {
    imgSrc:  url+'microshop/microshop/provide_2.png',
    title: '全功能的店铺运营管理',
    desc: ['一键发布商品', '多店铺全渠道统一管理', '提高商品、库存、订单处理效率', '帮您高效便捷运营旺铺']
  },
  {
    imgSrc:  url+'microshop/microshop/provide_3.png',
    title: '专属定制的营销玩法',
    desc: ['满减、砸金蛋、拼团、砍价、秒杀等多款社交电', '商新玩法，告别传统营销方式']
  },
  {
    imgSrc:  url+'microshop/microshop/provide_4.png',
    title: '强大的分销推广体系',
    desc: ['分销裂变', '人人都是推广员', '超强的分销推广转换', '帮您的店铺实现裂变式的效率增长']
  },
  {
    imgSrc:  url+'microshop/microshop/provide_5.png',
    title: '系统化的会员客户管理',
    desc: ['多会员入口', '立体化用户画像多维度分析', '帮您了解您的客户，智能维护客户关系']
  },
  {
    imgSrc:  url+'microshop/microshop/provide_6.png',
    title: '多维度的店铺数据分析',
    desc: ['全方位、多角度的店铺经营据分析尽在掌握之中客', '户喜好智能分析，帮助商家及时调整经营策略']
  }
]

export default class MicroMall extends React.Component {
  
  render() {
    return (
      <div className="micro_mall_page">
        <img className="banner_img" src={url+'microshop/microshop/header.png'}  alt="" />
        <section className="section1">
          <TitleCmp title="不只是一个移动电商" />
          <div className="list">
            {advantageData && advantageData.map((element, index) => {
              return (
                <div className="item" style={(index + 1) % 2 == 1 ? {marginRight: '0.3rem'} : {}} >
                  <img className="imgbg" src={element.imgSrc} alt="" />
                  <div className="title">{element.title}</div>
                  <div className="desc">{element.desc}</div>
                </div>
              )
            })}
          </div>
        </section>
        <section className="section2">  
            <TitleCmp title="我们为您提供了" />
            <div className="list">
              {
                serviceData.map((element, index) => {
                  return (
                    <div className="item" style={index == serviceData.length -1 ? { paddingBottom: '0.76rem'} : {}}>
                      <img className="imgbg" src={element.imgSrc} alt="" />
                      <div className="title">{element.title}</div>
                      {
                        element.desc.map((ele, index) => {
                          return (
                            <div className="desc">{ele} </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
        </section>
      </div>
    )
  }
}