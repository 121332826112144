import { throttle } from './util';

export function client() {
  if (window.innerHeight !== undefined) {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  if (document.compatMode === 'CSS1Compat') {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };
  }
  return {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
}

export function setRem() {
  const clientInfo = client();
  const viewWidth = clientInfo.width;
  document.documentElement.style.fontSize = `${viewWidth / 7.5}px`;
}

export function setRemThrottle() {
  return throttle(setRem);
}

const initSize = () => {
  setRemThrottle();
  window.addEventListener('resize', setRemThrottle);
};

export default initSize;
