import React, { Component } from "react";
import url from '../../util/path'
const siteTxt = [
  [{ tit: '豪尚豪牛排', sub: '一家源于宝岛台湾的经典牛排餐厅，20多年来，专注台式牛排，以匠心打造经典口味。' },
  { tit: 'MyWadrobe', sub: '“My Wadrobe”从东方审美出发，运用复古的元素符号，融合自身的情感设计，形成独有的服装风格。' }],
  [{ tit: '奶油小生烘焙', sub: '沙丁鱼会过期，爱情也会过期，不知这个世界还有什么不会过期，习惯就好，但奶油小生的保质期是一天。' },
  { tit: '蓝颜酒水美食直送', sub: '生活本该有酒有菜，随意调动情绪因子，任意应用加减乘除，标准只有一个，就是此刻感觉真的够味！' }],
  [{ tit: '言志美术', sub: '“言以宣志，志达艺生”，言志美术一直秉承[言传身教，志存高远]的古典儒家思想学堂式办学理念。' },
  { tit: '忆触记发教育', sub: '忆触记发教育是一家全国连锁机构，主做学科速记，以科学的方法，专业的训练提升孩子的学习能力。' }],
  [{ tit: 'SO HAIR美业', sub: '每个人，都是一个风景；每个风景，都有自己的风格；在SO HAIR，与喜欢的自己，不期而遇。' },
  { tit: '爱上周周母婴', sub: '用心呵护，贴心服务，专业团队，科学护理，让周周母婴呵护你，在这最值得被温柔的时刻。' }],
  [{ tit: '广大家居', sub: '典承风韵，追求简雅，提高生活品质，选瑞安广大家居，把品质带回家，给生活添色彩。' },
  { tit: '盈家地产', sub: '盈家房地产，始终秉承“诚信高效”的企业精神和“创造财富，推动发展，成就事业”的经营理念。' }],
  [{ tit: '浪爪携宠出行', sub: '它只是你的一个宠物，你却是他的一生。浪爪携宠游，无关岁月，无关烟雨，留下只属于我们的记忆。' },
  { tit: '千岛乐', sub: '蹦蹦床跳跳床-快乐运动，轻松生活，周末度假就选千岛湖。' }],
  [{ tit: '天幕影业', sub: '天幕影业，以“打造华人文化传媒旗舰，传播优秀中华文化”为宗旨，以市场导向为根本，等你的加入。' },
  { tit: '大咖私人高端定制', sub: '高端私人旅行，只管说走就走，大咖替您安排一切，让您安心享受旅途的每一分钟。' }],
]

export default class Case extends Component {
    constructor(props) {
      super(props)
      this.state = {
        activeIndex: '0_0'
      }
      // this.topRef = React.createRef();
      this.sliderRef = React.createRef();
    }
    // componentDidMount() {
    //   this.topRef && this.topRef.current.scrollIntoView();
     
    // }
    handleClick = (val) => {
      this.setState({
        activeIndex: val
      })
    }
    render() {
      let { activeIndex } = this.state
      let currentArr = activeIndex.split('_')
      let current = currentArr[0]
      let secCurrent = currentArr[1]
      return (
        <div className='casepage' >
              <div className='caseImg'></div>
              <div className='industry'> 
                <div>
                  <div className='titleBlock'>
                      <text className='title'>行业案例</text>
                      <div className='line'></div>
                  </div>
                  </div>
                  <div style={{ position: 'relative'}}>
                    <div className="tab_wrap_box">
                     {/* <div className="tab_wrap_bg1"/>  */}
                     <div className="tab_wrap_bg2" /> 

                <div className="tab_wrap" ref={this.sliderRef}>
                        <div className={current === "0" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('0_0')}>线上商城</div>
                        <div className={current === "1" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('1_0')}>餐饮外卖</div>
                        <div className={current === "2" ? "tab_item tab_item_active" : "tab_item"}  onClick={() => this.handleClick('2_0')}>教育美学</div>
                        <div className={current === "3" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('3_0')}>门店预约</div>
                        <div className={current === "4" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('4_0')}>地产家居</div>
                        <div className={current === "5" ? "tab_item tab_item_active" : "tab_item"}  onClick={() => this.handleClick('5_0')}>旅游民宿</div>
                        <div className={current === "6" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('6_0')}>生活服务</div>
                      </div>
                    </div>

                    <div className="solution_sec2">
                      <div className="tab_item_wrap"  onClick={() => {
                        let _index = `${current}_0`
                        this.handleClick(_index)
                      }}>
                        <img src={`${url}/case/ca_logo_0${Number(current) + 1}_01.png`} alt="" style={{ width: '0.56rem' }} />
                        <div className={activeIndex == `${current}_0` ? "tab_item tab_item_active" : 'tab_item'}>{siteTxt[current][0]['tit']}</div>
                      </div>
                      <div className="tab_item_wrap" onClick={() => {
                        let _index = `${current}_1`
                        this.handleClick(_index)
                      }}>
                        <img src={`${url}/case/ca_logo_0${Number(current) + 1}_02.png`} alt="" style={{ width: '0.56rem' }} />
                        <div className={activeIndex == `${current}_1` ? "tab_item tab_item_active" : 'tab_item'}>{siteTxt[current][1]['tit']}</div>
                      </div>
                    </div>
        
                    <div className="content_wrap">
                      <img src={require(`../../image/case/ca_logo_0${Number(current) + 1}_0${Number(secCurrent) + 1}.png`)} style={{ width: '0.72rem', margin: '0.93rem 0 0.27rem' }} alt="" />
                <div className="content_tit">{siteTxt[current][secCurrent]['tit']}</div>
                <div className="content_sub">{siteTxt[current][secCurrent]['sub']}</div>
                      <img src={require(`../../image/case/ca_qrcode_0${Number(current) + 1}_0${Number(secCurrent) + 1}.png`)} style={{ width: '2.6rem' }} alt="" />
                      <div className="content_tip">长按扫码识别体验</div>
                    </div>
                  </div> 
              </div>

          </div>

      )
    }


}