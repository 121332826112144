import React from 'react'
import { Carousel } from 'antd-mobile'
import url from '../util/path'
const image0 = url+'home/slide_image_1.png'
const image1 = url+'home/slide_image_2.png'
const image2 = url+'home/slide_image_3.png'
const image3 = url+'home/slide_image_4.png'


export default class CoolCarousalCmp extends React.Component {
  state = {
    data: [image0, image1, image2, image3],
    imgHeight: 176,
  }
  render() {
    let { history } = this.props
    return (
      <div className="cool_carousal_cmp">
        <Carousel 
          className="space-carousel"
          frameOverflow="visible"
          cellSpacing={0}
          slideWidth={0.9}
          autoplay
          infinite
          afterChange={index => this.setState({ slideIndex: index })}
        >
          {this.state.data.map((val, index) => (
            <div
              onClick={() => {
                history && history.push('/microMarketing')
              }}
              key={val}
              style={{
                display: 'block',
                position: 'relative',
                // top: this.state.slideIndex === index ? -10 : 0,
                top: 0,
                height: this.state.imgHeight,
                // boxShadow: '2px 1px 1px rgba(0, 0, 0, 0.2)',
              }}
            >
              <img
                src={val}
                alt=""
                style={{ width: '100%', verticalAlign: 'top' }}
                onLoad={() => {
                  // fire window resize event to change height
                  window.dispatchEvent(new Event('resize'));
                  this.setState({ imgHeight: 'auto' });
                }}
              />
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}