import React from 'react'
import TitleCmp from '../../components/TitleCmp'
import url from '../../util/path'

const advantageData = [
  {
    imgSrc: url+'microshop/microsite/perspective_1.png',
    title: '可视化搭建',
    desc: '店铺装修可视化，几十种组件自由组合'
  },
  {
    imgSrc: url+'microshop/microsite/perspective_2.png',
    title: '裂变式推广',
    desc: '社交裂变玩法新，拉新引流效果好'
  },
  {
    imgSrc: url+'microshop/microsite/perspective_3.png',
    title: '多流量沉淀',
    desc: '公众号、小程序多渠道帮商家快速获客'
  },
  {
    imgSrc: url+'microshop/microsite/perspective_4.png',
    title: '高收益变现',
    desc: '低成本帮助企业品牌成功高转化'
  },
]

const industryData = [
  {
    icon: require('../../image/microStation/icon_1.png'),
    title: '企业官网'
  },
  {
    icon: require('../../image/microStation/icon_2.png'),
    title: '酒店 '
  },
  {
    icon: require('../../image/microStation/icon_3.png'),
    title: '餐饮'
  },
  {
    icon: require('../../image/microStation/icon_4.png'),
    title: '美化'
  },
  {
    icon: require('../../image/microStation/icon_5.png'),
    title: '教育'
  },
  {
    icon: require('../../image/microStation/icon_6.png'),
    title: '房产'
  },
  {
    icon: require('../../image/microStation/icon_7.png'),
    title: 'ktv'
  },
  {
    icon: require('../../image/microStation/icon_8.png'),
    title: '金融'
  },
  {
    icon: require('../../image/microStation/icon_9.png'),
    title: '汽车'
  },
  {
    icon: require('../../image/microStation/icon_10.png'),
    title: '鲜花'
  },
  {
    icon: require('../../image/microStation/icon_11.png'),
    title: '医疗'
  },
  {
    icon: require('../../image/microStation/icon_12.png'),
    title: '婚庆'
  },
  {
    icon: require('../../image/microStation/icon_13.png'),
    title: '保险'
  },
  {
    icon: require('../../image/microStation/icon_14.png'),
    title: '旅游'
  },
  {
    icon: require('../../image/microStation/icon_15.png'),
    title: '珠宝'
  },
  {
    icon: require('../../image/microStation/icon_16.png'),
    title: '电影'
  },
  {
    icon: require('../../image/microStation/icon_17.png'),
    title: '美容'
  },
  {
    icon: require('../../image/microStation/icon_18.png'),
    title: '母婴'
  },
  {
    icon: require('../../image/microStation/icon_19.png'),
    title: '洗浴'
  },
  {
    icon: require('../../image/microStation/icon_20.png'),
    title: '家装'
  },
  {
    icon: require('../../image/microStation/icon_21.png'),
    title: '通讯'
  },
  {
    icon: require('../../image/microStation/icon_22.png'),
    title: '农产品'
  },
  {
    icon: require('../../image/microStation/icon_23.png'),
    title: '便超'
  },
  // {
  //   icon: require('../../image/microStation/icon_24.png'),
  //   title: '更多'
  // },
]
const serviceData = [
  {
    imgSrc: url+'microshop/microsite/provide_1.png',
    title: '海量精美的行业模板',
    desc: ['覆盖30+行业的企业官网模版 ',' 随意选择使用，三分钟生成专属您的官网小程序']
  },
  {
    imgSrc: url+'microshop/microsite/provide_2.png',
    title: '个性化的表单功能',
    desc: ['表单自定义随时收集想要的客户数据，让您想要的', '信息尽在掌握之中']
  },
  {
    imgSrc: url+'microshop/microsite/provide_3.png',
    title: '精准的数据分析',
    desc: ['精准的数据分析让客户了解您的产品及企业，让您更', '了解您的客户']
  },
  {
    imgSrc: url+'microshop/microsite/provide_4.png',
    title: '尽可能多的流量场景入口',
    desc: ['告别传统网站痛点借力微信诸多场景流量入口覆', '盖各个场景，多渠道提升客单转化']
  },
]

export default class MicroStation extends React.Component {

  render() {
    return (
      <div className="micro_station_page" >
        <img className="banner_img" src={url+'microshop/microsite/header.png'} alt="" />
        <section className="section1">
          <TitleCmp title="告别传统建站，小个体实现大价值" />
          <div className="list">
            {advantageData && advantageData.map((element, index) => {
              return (
                <div className="item" style={(index + 1) % 2 == 1 ? {marginRight: '0.3rem'} : {}} >
                  <img className="imgbg" src={element.imgSrc} alt="" />
                  <div className="title">{element.title}</div>
                  <div className="desc">{element.desc}</div>
                </div>
              )
            })}
          </div>
        </section>
        <section className="section2">
          <TitleCmp title="适用行业" />
          <div className="list">
            {
              industryData.map((element, index) => {
                return (
                  <div className="item" style={(index + 1) % 4 == 0 ? {marginRight: '0'} : { marginRight: '0.753rem'}}>
                    <img className="icon_bg" src={element.icon} alt="" />
                    <div className="title">{element.title}</div>
                  </div>
                )
              })
            }

          </div>
        </section>
        <section className="section3">  
            <TitleCmp title="我们为您提供了" />
            <div className="list">
              {
                serviceData.map((element, index) => {
                  return (
                    <div className="item" style={index == serviceData.length -1 ? { paddingBottom: '0.76rem'} : {}}>
                      <img className="imgbg" src={element.imgSrc} alt="" />
                      <div className="title">{element.title}</div>
                      {
                        element.desc.map((ele, index) => {
                          return (
                            <div className="desc">{ele} </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
        </section>
        <img src={url+'microshop/microsite/footer.png'} style={{ width: '7.5rem'}} alt="" />
      </div>
    )
  }
}