import React, { PureComponent } from 'react';
import ClickPop from '../../src/components/ClickPop'
import { Modal } from 'antd-mobile';


export default class Footer extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
        };
        this.onClose = this.onClose.bind(this)
    }
  
    onClose = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        let { visible } = this.state;
        let { onClosebigImg, bigImg, bigImg2 } = this.props
        // console.log('bigImg2 ', bigImg2)
        return (
            <div className="footer_com">
                <Modal 
                    visible={visible}
                    transparent
                    className= "modal"
                    wrapClassName="modal"
                    maskClosable={true}
                    onClose={this.onClose}
                    // title="Title"
                    // footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}
                    // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                    // afterClose={() => { alert('afterClose'); }}
                >
                    <ClickPop closeFun={this.onClose} /> 
                </Modal>
                
                <section className="section1 foot_bg">
                    <div className="img_content">
                        <div className="img_content_word">
                            等不及了,<span style={{ paddingLeft: '0.20rem' }}></span>带我一个!
                        </div>
                        <div className="img_content_tip">
                            免费体验专属小程序
                        </div>
                        <div className="img_content_btn"
                         onClick={() => {
                             this.setState({ visible:true})
                        }} >立即体验</div>
                       


                    </div>


                </section>
                <section className="section2">
                    <div className="footer_content">
                        <div className="footer_menu" style={{ marginRight: '0.50rem' }}>
                            <div className="footer_menu_title">关于我们</div>
                            <div className="footer_menu_list">
                                <div className="footer_menu_item">咨询热线：0571-89987488</div>
                                <div className="footer_menu_item">周一至周六 9：00-21：00</div>
                                <div>总部：浙江省杭州市拱墅区祥园路45号</div>
                            </div>
                        </div>
                        <div className="footer_menu">
                            <div className="footer_menu_title">点击扫码</div>
                            <div className="footer_menu_list">
                                <div className="img_wrap">
                                    <div className='bigImg'  style={bigImg ? {} : {display: 'none'}}   
                                    onClick={(ev)=>{
                                        ev.stopPropagation()
                                    }
                                    }>
                                    <img src={require('../image/component/footer/applet.jpg')} alt="" className="img_wrap_applebig" />
                                    <div className='xcx'> 长按扫码关注小程序</div>
                                    </div>
                                    <img src={require('../image/component/footer/applet.jpg')} alt="" className="img_wrap_apple" 
                                    onClick={(ev) => {
                                        ev.preventDefault()
                                        ev.stopPropagation()
                                        onClosebigImg && onClosebigImg('bigImg')
                                    }}/>
                                    <div style={{ lineHeight: "0.30rem" ,width: "0.66rem" }}>小程序</div>
                                    
                                </div> 
                                <div className="img_wrap" style={{marginLeft:"0.40rem"}}>
                                    <div className='bigImg bigImg2' style={bigImg2 ? {} : { display: 'none' }}
                                        onClick={(ev) => {
                                            ev.stopPropagation()
                                        }
                                        }>
                                        <img src={require('../image/component/footer/qa_code.jpg')} alt="" className="img_wrap_applebig" 
                                            
                                        />
                                        <div className='xcx'> 长按扫码关注小程序</div>
                                    </div>
                                    <img src={require('../image/component/footer/qa_code.jpg')} alt="" className="img_wrap_apple"
                                        onClick={(ev) => {
                                            ev.preventDefault()
                                            ev.stopPropagation()

                                            onClosebigImg && onClosebigImg('bigImg2') 
                                        }
                                        }
                                    />
                                    <div style={{ lineHeight:"0.30rem" ,width:"0.66rem" }}>公众号</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="footer_componey_info">
                        <div className="footer_componey1">
                            Copyright © 2004-2019 www.panshi101.com. All rights reserved.
                        </div>
                        <div className="footer_componey2">
                            <div>浙江盘兴信息技术股份有限公司及 All Rights Reserved </div>
                            <div className='icp' >
                                <a style={{ color:'#666666'}} href="http://beian.miit.gov.cn/" target="_blank">
                                    浙ICP备18048879号-1  
                                </a>
                            </div>  
                        </div>
                    </div>

                </section>


            </div>   





        )
    }



}
