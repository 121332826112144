import React from 'react'

const questionList = [
  'Q1：微信公众号注册流程？',
  'Q2：如何授权微信公众号？',
  'Q3：如何自定义公众号菜单？',
  'Q4：如何注册小程序？',
  'Q5：如何申请微信支付？',
  'Q6：如何在线上传小程序？',
  'Q7：如何进行支付参数配置？',
  'Q8：如何在线更新小程序？'
]

export default class AnserListOne extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isTop: true,
      showMore: false,
      topDis: '-2.59rem',
      activeIndex: 0,
    }
    this.caseScrollHandle = this.caseScrollHandle.bind(this)
  }
  componentDidMount() {
    document.getElementById("root").classList.remove("root_box");
    window.addEventListener('scroll', this.caseScrollHandle);
  }
  componentWillUnmount() {
    document.getElementById("root").classList.add("root_box");
    window.removeEventListener('scroll', this.caseScrollHandle)
  }
  caseScrollHandle() {
    const t = document.documentElement.scrollTop || document.body.scrollTop;
    if(t == 0) {
      this.setState({
        isTop: true
      })
    } else {
      this.setState({
        isTop: false
      })
    }
  }
  render() {
    let { topDis, showMore, isTop, activeIndex } = this.state
    return (
      <div className="answer_list_page">
        <div className="title">新手帮助</div>
        {
          !isTop && 
          <div className="question_item_wrap">
            <ul className="question_list">
              {
                !showMore && <li className="question_item"><a href={`#helper-question-${activeIndex + 1}`} className="category-item-link">{questionList[activeIndex]}</a></li>
              }
              {showMore && 
                <>
                  {questionList.map((element,index) => {
                    return  <li className="question_item" onClick={() => {
                      this.setState({
                        activeIndex: index,
                        showMore: false,
                        topDis: '-2.59rem',
                      })
                    }}><a href={`#helper-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                  })}
                </>
              }
            </ul>
            <div className="show_more" onClick={() => {
              this.setState({
                showMore: !showMore
              }, () => {
                let { showMore } = this.state;
                this.setState({
                  topDis: showMore ? '-6.37rem' : '-2.59rem'
                })
              })
            }}>{showMore ? '收起更多问题' : '展开更多问题'}</div>
          </div>
        }
        {
          isTop && 
          <ul className="question_list">
            {questionList.map((element,index) => {
              return  <li className="question_item" onClick={() => {
                this.setState({
                  activeIndex: index,
                  showMore: false,
                  topDis: '-2.59rem',
                })
              }}><a href={`#helper-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
            })}
          </ul>
        }
      

   
        <div className="answerList">
          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-1" />
            <div className="solution-title">Q1：微信公众号注册流程？</div>
            <div className="solution-content">A1：通过电脑登录微信公众平台官网：http://mp.weixin.qq.com，点击右上角的“立即注册”并选择注册账号的类型。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q1-1.png')} />
            <div className="solution-content">填写注册邮箱并设置公众号登录密码</div>
            <img className="helper-image" src={require('../../image/answerList/help-q1-2.png')} />
            <div className="solution-content">登录邮箱查看邮件并激活公众平台账号，其后选择合适自身的公众号类型（包括订阅号、服务号、企业号），而后即可进入信息登记页面，填写公众号信息、认证信息，然后再次确认填写认证的公众号名称，确认信息，支付每年300元的腾讯认证费用，等待腾讯认证第三方致电即可完成认证。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q1-3.png')} />
          </div>

          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-2" />
            <div className="solution-title">Q2：如何授权微信公众号？</div>
            <div className="solution-content">A：在后台——“渠道”——“公众号配置”中，点击“立即绑定”，管理员扫码授权绑定公众号，默认将所有权限勾选授予平台。（注：扫码后，请点击选择公众号，勿错选绑定小程序）</div>
            <img className="helper-image" src={require('../../image/answerList/help-q2-1.png')} />
          </div>

          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-3" />
            <div className="solution-title">Q3：如何自定义公众号菜单？</div>
            <div className="solution-content">A：在“渠道”-“自定义菜单”中，点击“+”新建主菜单，可自定义菜单名、菜单排序和菜单内容，其中菜单内容可为文字消息、跳转指定小程序、跳转指定链接和跳转指定页面等，一个公众号最多可设置三个主菜单。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q3-1.png')} />
            <div className="solution-content">点击每个主菜单上方的“+”，可给该主菜单添加子菜单，每个主菜单下最多可添加五个子菜单。（注：当主菜单下添加子菜单后，原主菜单的菜单内容会被子菜单覆盖。）菜单设置好后，点击“保存并发布”即可。（注：主/子菜单内容均不可为空。）</div>
            <img className="helper-image" src={require('../../image/answerList/help-q3-2.png')} />
          </div>

          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-4" />
            <div className="solution-title">Q4：如何注册小程序？</div>
            <div className="solution-content">A：商家需要在微信公众平台官网首页（https://mp.weixin.qq.com/）点击右上角的“立即注册”按钮，注册一个微信小程序帐号。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q4-1.png')} />
            <div className="solution-content">注册小程序后，登录小程序后台首页，点击【填写】完善小程序信息，如名称、图标、描述等；</div>
            <img className="helper-image" src={require('../../image/answerList/help-q4-2.png')} />
            <img className="helper-image" src={require('../../image/answerList/help-q4-3.png')} />
            <img className="helper-image" src={require('../../image/answerList/help-q4-4.png')} />
          </div>

          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-5" />
            <div className="solution-title">Q5：如何申请微信支付？</div>
            <div className="solution-content">A: 需申请主体认证，通过后即可申请微信支付。个人小程序暂不支持申请微信支付。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q5-1.png')} />
            <div className="solution-content">成功申请小程序微信支付后，通过链接https://pay.weixin.qq.com 登录微信支付商户平台，获取商户号、商户密钥、下载 API 支付证书，各参数获取说明如下说明：
获取商户号：点击“账户中心”->“商户信息”菜单，获取微信支付商户号，如下图示；</div>
            <img className="helper-image" src={require('../../image/answerList/help-q5-2.png')} />
            <div className="solution-content">商家密钥：点击“账户中心”->“API 安全”菜单，设置 32 位密钥并记录备份，如下图
          示；注意：密钥填写错误会导致支付异常，如期间修改请重新登录商城后台同步修改参数。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q5-3.png')} />
            <div className="solution-content">获取 API 证书：点击“账户中心”->“API 安全”菜单，点击【下载证书】，按提示验证身份后下载证书文件，并解压，后续需上传“apiclient_cert.p12”如图中所示资料到商城后台；</div>
            <img className="helper-image" src={require('../../image/answerList/help-q5-4.png')} />
            <img className="helper-image" src={require('../../image/answerList/help-q5-5.png')} />
          </div>

          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-6" />
            <div className="solution-title">Q6：如何在线上传小程序？</div>
            <div className="solution-content">A：将小程序在线上传，授权到平台，便可在平台直接完成小程序编辑、小程序审核、小程序发布操作，操作方便快捷，适合一般用户选择使用。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q6-1.png')} />
            <div className="solution-content">需登录管理后台，点击【店铺】菜单->点击【发布小程序】->点击【立即授权】按钮，由小程序管理员扫码授权绑定；</div>
            <img className="helper-image" src={require('../../image/answerList/help-q6-2.png')} />
            <div className="solution-content">授权时必须选择以下权限，漏选可能导致小程序功能无法正常使用。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q6-3.png')} />

          </div>

          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-7" />
            <div className="solution-title">Q7：如何进行支付参数配置？</div>
            <div className="solution-content">已申请微信支付的商户可在“支付方式”——“支付配置”——“支付商户类型”中选择“普通商户”，输入微信支付商户号、商户密钥、上传微信支付 API 证书，完成支付参数配置。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q7-1.png')} />
          </div>

          <div className="solution-item">
            <div style={{ position: 'absolute', top: topDis }} id="helper-question-8" />
            <div className="solution-title">Q8：如何在线更新小程序？</div>
            <div className="solution-content">授权绑定小程序后，在“打包代码”页面点击【一键打包】按钮打包小程序；
提示：请时刻留意该页面版本更新情况，及时更新最新软件版本，达到最佳体验效果。</div>
            <img className="helper-image" src={require('../../image/answerList/help-q8-1.png')} />
            <div className="solution-content">每点击【一键打包】打包小程序，在“微信审核”页面会将会生成一条打包记录，点击【提交审核】将代码包提交到微信进行审核，微信审核周期一般为7 个工作日，在提交审核前请先认真阅读《微信小程序平台运营规范》和《微信小程序平台常见拒绝情形》；审核通过：微信审核通过后，点击【发布】按钮，稍等片刻，即可在手机微信小程序搜索中查到已发布的小程序，完成发布流程；</div>

          </div>
        </div>
      </div>
    )
  }
}