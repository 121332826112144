import React, { Component } from 'react'
import url from '../../util/path'
export default class About extends Component {
    render() {
        return (
            <div className="aboutus">
                <img src={url+'aboutus/header.png'} alt="" className='showImg' />
                <div className='titleBlock'>
                    <text className='title'>关于我们</text>
                    <div className='line'></div>
                </div>
                <img src={url+'aboutus/aboutus.png'} alt="" className='showImg' />
                <div ><p className='content'>
                    微享汇，是浙江盘兴信息技术股份有限公司旗下RockySaaS云服务推出的旗舰产品。</p>

                    <p className='content'>  基于移动社交的核心价值，微享汇为商家提供了适用于各行各业的微信生态解决方案， 通过科技驱动商业革新，
                能为企业无缝整合基础业务和衍生业务， 全面帮助商家拓展互联网客群、提高经营效率，助力中小企业向数字化转型。</p>

                    <p className='content'> 微享汇，移动社交电商的智慧服务平台！
                    </p>
                    <div className='titleBlock'>
                        <text className='title'>联系我们</text>
                        <div className='line'></div>
                    </div>

                </div>
                <img src={url+'aboutus/contactus.png'} alt="" className='showImg' />
                <div className='service'>
                    <div>电话客服： 0571-89987488（仅收市话费）</div>
                    <div>工作时间：周一 至 周六 9:00-11:30,13:30-17:00</div></div>

                <div className=""></div>
            </div>
        )
    }
}