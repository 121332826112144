export function throttle(method, delay = 100, context = window) {
  if (!method.tId) {
    method.call(context);
    method.tId = 1;
    setTimeout(() => method.tId = 0, delay);
  }
}

export function debounce(method, delay = 100, context = window) {
  method.tid && clearTimeout(method.tid);
  method.tid = setTimeout(() => method.call(context), delay);
}

export function getIEVersion() {
  const { userAgent } = navigator; // 取得浏览器的userAgent字符串
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; // 判断是否IE<11浏览器
  // const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
  const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
  if (isIE) {
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
    reIE.test(userAgent);
    const fIEVersion = parseFloat(RegExp.$1);
    if (fIEVersion == 7) {
      return 7;
    } if (fIEVersion == 8) {
      return 8;
    } if (fIEVersion == 9) {
      return 9;
    } if (fIEVersion == 10) {
      return 10;
    }
    return 6;// IE版本<=7
  } if (isIE11) {
    return 11;
  }
  return -1;
  // else if(isEdge) {
  //     return 'edge';//edge
  // } else if(isIE11) {
  //     return 11; //IE11
  // }else{
  //     return -1;//不是ie浏览器
  // }
}

export function randomString(length) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

/**
   * 格式化时间
   *
   * @param  {time} 时间
   * @param  {cFormat} 格式
   * @return {String} 字符串
   *
   * @example formatTime('2018-1-29', '{y}/{m}/{d} {h}:{i}:{s}') // -> 2018/01/29 00:00:00
   */
export function formatTime(time, cFormat) {
  let timeTemp = time;
  if (arguments.length === 0) return null;
  if ((`${timeTemp}`).length === 10) {
    timeTemp = +timeTemp * 1000;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof timeTemp === 'object') {
    date = timeTemp;
  } else {
    date = new Date(timeTemp);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    if (result.length > 0 && value < 10) {
      value = `0${value}`;
    }
    return value || 0;
  });
  return timeStr;
}


export function IsPC() {
  const userAgentInfo = navigator.userAgent;
  const Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod');
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    // if (userAgentInfo.indexOf(Agents[v]) > 0) {
    //   flag = false;
    //   break;
    // }
    if (userAgentInfo.search(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

export function getBrowserInterfaceSize () {
  let pageWidth = window.innerWidth;
  let pageHeight = window.innerHeight;

  if (typeof pageWidth != 'number') {
    // 在标准模式下面
    if (document.compatMode == 'CSS1Compat') {
      pageWidth = document.documentElement.clientWidth;
      pageHeight = document.documentElement.clientHeight;
    } else {
      pageWidth = document.body.clientWidth;
      pageHeight = window.body.clientHeight;
    }
  }
  return {
    pageWidth,
    pageHeight,
  };
}


export default {
  throttle,
  debounce,
  getIEVersion,
  formatTime,
  getBrowserInterfaceSize
};
