import React from 'react'

const tabArr = [
  {
    key: 0,
    bgSrc: require('../image/component/solutionCmp/commerce/bg.png'),
    iconArr: [
      require('../image/component/solutionCmp/commerce/icon_1.png'),
      require('../image/component/solutionCmp/commerce/icon_2.png'),
      require('../image/component/solutionCmp/commerce/icon_3.png'),
      require('../image/component/solutionCmp/commerce/icon_4.png'),
      require('../image/component/solutionCmp/commerce/icon_5.png'),
      require('../image/component/solutionCmp/commerce/icon_6.png'),
      require('../image/component/solutionCmp/commerce/icon_7.png'),
    ],
    iconDescArr: ['在线商城', '客户管理', '会员系统', '分销系统', '拼团', '营销插件', '数据分析'],
    title: '电商行业解决方案',
    desc: '随着移动互联网的快速发展，传统电商增长乏力，离天花板越来越近，在互联网新增用户接近饱和状态下，引流成本越来越高。而社交电商具备了传统电商不可比拟的优势——裂变，故不需要集中引流，而是将引流任务转嫁给以个体为主的分销商或代理商。因此社交电商可以占据传统电商难以下沉的三线城市市场，实现高覆盖和高成交',
    feature1: ['价格战竞争恶劣','假货文化泛滥','虚拟化电子网络诚信危机']
  },
  {
    key: 1,
    bgSrc: require('../image/component/solutionCmp/departmentStore/bg.png'),
    iconArr: [
      require('../image/component/solutionCmp/departmentStore/icon_1.png'),
      require('../image/component/solutionCmp/departmentStore/icon_2.png'),
      require('../image/component/solutionCmp/departmentStore/icon_3.png'),
      require('../image/component/solutionCmp/departmentStore/icon_4.png'),
      require('../image/component/solutionCmp/departmentStore/icon_5.png'),
      require('../image/component/solutionCmp/departmentStore/icon_6.png'),
      require('../image/component/solutionCmp/departmentStore/icon_7.png'),
      require('../image/component/solutionCmp/departmentStore/icon_8.png'),
    ],
    iconDescArr: ['门店管理', '到店自提', '会员积分', '活动促销', '当面付', '小票打印', '线下收款', '扫码购物'],
    title: '百货行业解决方案',
    desc: '随着互联网的不断发展，相关法律和金融保障的健全，线上购物成为流行，电子商务使固定地点、固定面积、经营相对稳定的超市百货带来了极大威胁。因此只有实现线上线下互通、实现数字化转型方能迎接变化，持续发展',
    feature1: ['用户线上到线下转化','运营办公网络不完善','实体店铺成本增加']
  },
  {
    key: 2,
    bgSrc: require('../image/component/solutionCmp/chain/bg.png'),
    iconArr: [
      require('../image/component/solutionCmp/chain/icon_1.png'),
      require('../image/component/solutionCmp/chain/icon_2.png'),
      require('../image/component/solutionCmp/chain/icon_3.png'),
      require('../image/component/solutionCmp/chain/icon_4.png'),
      require('../image/component/solutionCmp/chain/icon_5.png'),
      require('../image/component/solutionCmp/chain/icon_6.png'),
      require('../image/component/solutionCmp/chain/icon_7.png'),
      require('../image/component/solutionCmp/chain/icon_8.png'),
    ],
    iconDescArr: ['门店管理', '线上促销', '会员管理', '库存管理', '当面付', '小票打印', '线下收款', '门店定位'],
    title: '连锁行业解决方案',
    desc: '互联网时代的崛起使得传统行业面临危机，中国连锁市场呈现高饱和度和高竞争局面，因此连锁企业应改变原有的经营模式，改变单一的销售模式，进行多元化销售，进而实现资源的全面整合',
    feature1: ['公众事件频发','租金和劳动力成本上涨','电商带来巨大压力']
  },
  {
    key: 3,
    bgSrc: require('../image/component/solutionCmp/food/bg.png'),
    iconArr: [
      require('../image/component/solutionCmp/food/icon_1.png'),
      require('../image/component/solutionCmp/food/icon_2.png'),
      require('../image/component/solutionCmp/food/icon_3.png'),
      require('../image/component/solutionCmp/food/icon_4.png'),
      require('../image/component/solutionCmp/food/icon_5.png'),
      require('../image/component/solutionCmp/food/icon_6.png'),
      require('../image/component/solutionCmp/food/icon_7.png'),
    ],
    iconDescArr: ['排队取号', '扫码点餐', '订单提醒', '外卖配送', '桌号管理', '小票打印', '堂食外卖'],
    title: '餐饮行业解决方案',
    desc: '中国餐饮市场在业态、质量、地域等方面发展不平衡，整个产业链发展不完善、产业集中程度不高、人均餐饮消费水平低',
    feature1: ['餐饮市场分散','专业人才培养不足','连锁餐饮标准化程度较低']
  },
  {
    key: 4,
    bgSrc: require('../image/component/solutionCmp/beautyIndustry/bg.png'),
    iconArr: [
      require('../image/component/solutionCmp/beautyIndustry/icon_1.png'),
      require('../image/component/solutionCmp/beautyIndustry/icon_2.png'),
      require('../image/component/solutionCmp/beautyIndustry/icon_3.png'),
      require('../image/component/solutionCmp/beautyIndustry/icon_4.png'),
      require('../image/component/solutionCmp/beautyIndustry/icon_5.png'),
      require('../image/component/solutionCmp/beautyIndustry/icon_6.png'),
    ],
    iconDescArr: ['线上预约', '线下核销', '服务展示', '活动营销', '次卡消费', '会员管理'],
    title: '美业行业解决方案',
    desc: '传统美业由于模式的老旧、服务方式的过失，导致了客户的大量流失，与此同时低门槛准入导致市场竞争日渐激烈，同时也带来了市场混乱。但市场困局的背面也是新的机遇，美业必须拥抱互联网，实现转型，方能让自己不断成长',
    feature1: ['扩展客源困难','行业人才的缺乏','对产品定位不够准确']
  },
  {
    key: 5,
    bgSrc: require('../image/component/solutionCmp/hotel/bg.png'),
    iconArr: [
      require('../image/component/solutionCmp/hotel/icon_1.png'),
      require('../image/component/solutionCmp/hotel/icon_2.png'),
      require('../image/component/solutionCmp/hotel/icon_3.png'),
      require('../image/component/solutionCmp/hotel/icon_4.png'),
      require('../image/component/solutionCmp/hotel/icon_5.png'),
    ],
    iconDescArr: ['房型预约', '订单管理', '房间管理', '会员管理', '支付押金'],
    title: '酒店行业解决方案',
    desc: '共享经济下对传统酒店业产生巨大冲击，技术变革又深刻改变着酒店业的运营服务模式，酒店服务业已经从管理模式走向经营模式，互联网成为酒店行业的底层架构，因此适应环境的快速变化、了解和判断行业走势方能在激烈的竞争中胜出',
    feature1: ['可供远大于求','风格、功能同质化','重集团化、轻连锁化', '重投资、轻管理']
  },
]

export default class SolutionCmp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0 // 0 电商 1 百货 2 连锁 3 餐饮 4 美业 5 酒店
    }
  }
  changeIndex = (idx) => {
    this.setState({
      activeIndex: idx 
    })
  }

  render() {
    let { activeIndex } = this.state
    return (
      <div className="solution_cmp"  style={{ backgroundImage: 'url(' + tabArr[activeIndex].bgSrc + ')' }}>
        <div style={{width: 0, height: 0}}>
          {tabArr.map((element) => {
            return <img key={element.key} src={element.bgSrc} alt="" style={{width: 0, height: 0}} />
          })}
        </div>
        <div className="solution_sec1">覆盖各行业的解决方案</div>
        <div className="solution_sec2">
          <div className={activeIndex == 0 ? "tab_item tab_item_active" : 'tab_item'} onClick={() => {
            this.changeIndex(0)
          }}>电商</div>
          <div className={activeIndex == 1 ? "tab_item tab_item_active" : 'tab_item'} onClick={() => {
            this.changeIndex(1)
          }}>百货</div>
          <div className={activeIndex == 2 ? "tab_item tab_item_active" : 'tab_item'} onClick={() => {
            this.changeIndex(2)
          }}>连锁</div>
          <div className={activeIndex == 3 ? "tab_item tab_item_active" : 'tab_item'} onClick={() => {
            this.changeIndex(3)
          }}>餐饮</div>
          <div className={activeIndex == 4 ? "tab_item tab_item_active" : 'tab_item'} onClick={() => {
            this.changeIndex(4)
          }}>美业</div>
          <div className={activeIndex == 5 ? "tab_item tab_item_active" : 'tab_item'} onClick={() => {
            this.changeIndex(5)
          }}>酒店</div>
        </div>
        <div className="solution_sec3">
          <div className="solution_desc_wrap">
            <div className="sub_title" style={{ margin: '0.41rem 0 0.11rem'}}>
              行业现状：
            </div>
            <div className="desc">
              {tabArr[activeIndex].desc}
            </div>
            <div className="sub_title" style={{ margin: '0.29rem 0 0.21rem'}}>
              行业痛点：
            </div>
            <div className="advantage_list">
              {tabArr[activeIndex].feature1.map((element,key) => {
                return (
                  <div className="advantage_item" key={key}>
                    <span style={{ paddingRight: '0.2rem' }}>{key + 1}</span>{element}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="solution_icon_list">
            {tabArr[activeIndex].iconArr.map((element,key) => {
              return (
                <div className="solution_icon_item" key={key}>
                  <img className="solution_icon_item_img" src={element} alt="" />
                  <div className="solution_icon_item_title">{tabArr[activeIndex].iconDescArr[key]}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}