import React, { Component } from 'react';
import { Carousel } from 'antd-mobile';
import SolutionCmp from '../../components/SolutionCmp'
import CoolCarousalCmp from '../../components/CoolCarousalCmp'
import WxCodeCmp from '../../components/WxCodeCmp'
import MenuCmp from '../../components/MenuCmp'
import url from '../../util/path'

const operationList = [{
    title: '店铺搭建',
    content: '自由拖拽，海量模板，个性diy',
    picture: url+'home/shop.png',
    bgColor:'#ECF5FF',
    titleColor:'#4CA0FF',
    contentColor:'#A1C1E5',
    key:'1',
},
{
    title: '营销推广',
    content: '多款营销插件，助力社交 裂变，实现流量变现',
    picture: url+'home/gift.png',
    bgColor: '#FFF8E9', 
    titleColor: '#FF9B0E', 
    contentColor: '#F3BB77',
    key:'2',
},
{
    title: '流量运营',
    content: '多渠道引流，公域转私域， 流量变留量',
    picture: url+'home/flow.png',
    bgColor: '#EAFFF9',
    titleColor: '#29D7A8',
    contentColor: '#A0D8C9',
    key:'3',
},
{
    title: '运营服务',
    content: '会员管理，精准营销，智能分析',
    picture: url+'home/business.png',
    bgColor: '#FFF1E7',
    titleColor: '#FF9148',
    contentColor: '#E3AC88',
    key : '4',  
},

]

let Merchant = [{
  title: 'Anikoo美妆',
  content: 'ANikoo美妆主打爆款化妆品和热门产品，致力打造高品质、高价值的购物体验，引领全球美妆护肤新生态',
  componey: '',
  key: '1',
  graphic: url+'home/slide_enterprise_1.png',
  graphic1: require('../../image/home/qa_code1.png')
}, {
  title: '贝特咖啡',
  content: '贝特咖啡坚持如一，始终做一杯有态度的咖啡，感受不一样的甜蜜',
  componey: '',
  key: '2',
  graphic: url+'home/slide_enterprise_2.png',
    graphic1: require('../../image/home/qa_code2.png')
}, {
  title: '维俪肤医疗美容',
  content: '维俪肤医疗美容创立于2016年，是一家专注于皮肤管理、微整注射和整形外科的顶尖医疗整形医院',
  componey: '',
  key: '3',
  graphic: url+'home/slide_enterprise_3.png',
    graphic1: require('../../image/home/qa_code3.png')
}, {
  title: '兔窝镇',
  content: 'BUNNYBURROW(兔窝镇)"是杭州兔窝宠物用品有限公司与香港宠干线有限公司合作在全国建立的小宠物专营店，定位高端，专业致力于进口小宠物培育，互动式营销的新零售品牌',
  componey: '',
  key: '4',
  graphic: url+'home/slide_enterprise_4.png',
    graphic1: require('../../image/home/qa_code4.png')
}, {
  title: '绍美艺术',
  content: '绍美艺术成立于2012年，中心本着“普及、专业、创新、精品”的办学理念，用“专业导入式教学”开启孩子艺术之门，立志打造规模化、专业性、体验性的艺术梦工场',
  componey: '',
  key: '5',
  graphic: url+'home/slide_enterprise_5.png',
    graphic1: require('../../image/home/qa_code5.png')
}, {
  title: '礼正实业',
  content: '杭州礼正实业有限公司作为行业内领先的供应链集成化服务商，专注于协助企业解决从定位，企划，设计，制造，推广，销售，复盘等整体流程的问题',
  componey: '',
  key: '6',
  graphic: url+'home/slide_enterprise_6.png',
    graphic1: require('../../image/home/qa_code6.png')
}]

export default class Home extends Component {
  
    render() {
        let { history } = this.props
        return (
          <div className="home_page">
          <div className="homepage"></div>
                <div className="wcard-container">
                    <div className='titleBlock'>
                        <text className='title'>轻松玩转社交电商</text>
                        <div className='line'></div>
                    </div>
                    <div className="content">
                        <img src={url+'home/main_1.png'} alt="" className='showImg'/>
                        <div className="content-topic">把商城开到用户的微信里三秒搭建一个网店</div>
                        <div className="content-introduce">搭建手机移动商城无需下载APP 零技术零门槛、一键生成、三秒搭建 在线下单、在线支付</div>

                    </div>
                    <div className="content">
                        <img src={url+'home/main_2.png'} alt="" className='showImg' />
                        <div className="content-topic">精准智能广告获客</div>
                        <div className="content-introduce">紧跟热点营销节奏，自动化一对一营销链路，助理企业精准获客</div>

                   </div>
                    <div className="content">
                        <img src={url+'home/main_3.png'} alt="" className='showImg' />
                        <div className="content-topic">让营销更有趣</div>
                        <div className="content-introduce">会员卡、优惠券、分销 、团购、秒杀多重营销方式，提高转化率</div>

                    </div>
                    <div className="content" style={{height:"5.75rem"}}>
                        <img src={url+'home/main_4.png'} alt="" className='showImg' />
                        <div className="content-topic">不怕没有回头客</div>
                        <div className="content-introduce">商家独立后台 活动管理 商品管理 订单管理 会员管理 数据分析 精准营销 拉升客单</div>
                    </div>
                </div>
                <SolutionCmp />
                <div>
                        <div className='titleBlock'>
                            <text className='title' style={{ textAlign:'center' }}>丰富多样的营销玩法<br></br>
                                        覆盖所有经营场景</text>
                            <div className='line' style={{ marginTop:'0.60rem'}}></div>
                        </div>

                <CoolCarousalCmp history={history} />
                </div>
                <div className="operation">
                    <div className='titleBlock'>
                        <text className='title'>贴心运营</text>
                        <div className='line'></div>
                    </div>
                 < div className = "operationlist">
                        {
                              operationList.map(element => (
                                  <div style={{ background: element.bgColor }} className="operationitem">
                                      <img className="picture"  src={element.picture}/> 
                                      <div className="title" style={{ color: element.titleColor }}>{element.title}</div>
                                      <div className="content" style={{ color: element.contentColor }}>{element.content}</div>
                                  </div>

                                 ))
                         }


                    </div>
                </div>
                <div className="wxcodeWrap">
                    <div className='titleBlock'>
                        <text className='title'>感恩一路  一路有你</text>
                        <div className='line'></div>
                    </div>
                    

                    <div className='wxcodeWrapList'>
                      {
                        Merchant.map((element, index) => {
                          return  (
                            <div style={index == Merchant.length - 1 ? { marginRight: '0.28rem', marginBottom: '0.28rem'  } : {marginRight: '0.24rem' , marginBottom: '0.24rem'}}>
                                <WxCodeCmp data={element} key={element.key} isLast={index == Merchant.length - 1 ? true : false}/>
                            </div>
                          )
                        })
                      }
                    </div>
                </div>
                <div className="moreBtnWrap" onClick={() => {
                  console.log('点击了更多案例 history', history)
                  history && history.push('/case')
                }}>更多案例</div>


            </div>

         
           
        );
    }

}
