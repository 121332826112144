import React from 'react'
import TitleCmp from '../../components/TitleCmp'
import { Carousel } from 'antd-mobile'
import url from '../../util/path'
const industryData = [
  {
    icon: require('../../image/smartStore/icon_1.png'),
    title: '宠物园艺 '
  },
  {
    icon: require('../../image/smartStore/icon_2.png'),
    title: '运动健身 '
  },
  {
    icon: require('../../image/smartStore/icon_3.png'),
    title: '养生SPA'
  },
  {
    icon: require('../../image/smartStore/icon_4.png'),
    title: '数码3C'
  },
  {
    icon: require('../../image/smartStore/icon_5.png'),
    title: '生活服务'
  },
  {
    icon: require('../../image/smartStore/icon_6.png'),
    title: '连锁超市'
  },
  {
    icon: require('../../image/smartStore/icon_7.png'),
    title: '服装鞋视'
  },
  {
    icon: require('../../image/smartStore/icon_8.png'),
    title: '美甲美容'
  },
  {
    icon: require('../../image/smartStore/icon_9.png'),
    title: '民宿酒店'
  },
  {
    icon: require('../../image/smartStore/icon_10.png'),
    title: '休闲零食'
  },
  {
    icon: require('../../image/smartStore/icon_11.png'),
    title: '文化服务'
  },
  {
    icon: require('../../image/smartStore/icon_12.png'),
    title: '水果生鲜'
  },
  {
    icon: require('../../image/smartStore/icon_13.png'),
    title: '餐饮外卖'
  },
]
export default class SmartStore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [
        url+'microshop/smartshop/solution_1.png', 
        url+'microshop/smartshop/solution_2.png',
        url+'microshop/smartshop/solution_3.png',
        url+'microshop/smartshop/solution_4.png', 
        url+'microshop/smartshop/solution_5.png'],
    }
    this.topRef = React.createRef();
  }

  componentDidMount() {
    this.topRef && this.topRef.current.scrollIntoView();

  }
  render() {
    return (
      <div className="smart_store_page" ref={this.topRef}>
        <img className="banner_img" src={url+'microshop/smartshop/header.png'} alt="" />
        <section className="section1">
          <TitleCmp title="适用行业" />
          <div className="list">
            {
              industryData.map((element, index) => {
                return (
                  <div className="item" style={(index + 1) % 4 == 0 ? {marginRight: '0'} : { marginRight: '0.753rem'}}>
                    <img className="icon_bg" src={element.icon} alt="" />
                    <div className="title">{element.title}</div>
                  </div>
                )
              })
            }

          </div>
        </section>
        <section className="section2">
          <TitleCmp title="解决方案" />
          <div style={{ height: '0.26rem'}}></div>
          <Carousel
            autoplay={false}
            infinite
            beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
            afterChange={index => console.log('slide to', index)}
          >
            {this.state.data.map(val => (
              <img
                src={val}
                alt=""
                style={{ width: '100%', verticalAlign: 'top' }}
                onLoad={() => {
                  window.dispatchEvent(new Event('resize'));
                  this.setState({ imgHeight: 'auto' });
                }}
              />
            ))}
          </Carousel>
        </section>
      </div>
    )
  }
}