import React from 'react'

const questionList = [
  'Q1：如何查看数据统计？',
  'Q2：如何管理客户？',
  'Q3：如何管理会员？',
  'Q4：什么是分销？',
  'Q5：如何管理推广员？',
  'Q6：如何管理会员卡？',
  'Q7：会员储值如何使用？',
  'Q8：如何增加店铺复购率？'
]

export default class SocialOperation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isTop: true,
            showMore: false,
            topDis: '-2.59rem',
            activeIndex: 0,
        }
        this.caseScrollHandle = this.caseScrollHandle.bind(this)
    }
    componentDidMount() {
        document.getElementById("root").classList.remove("root_box");
        window.addEventListener('scroll', this.caseScrollHandle);
    }
    componentWillUnmount() {
        document.getElementById("root").classList.add("root_box");
        window.removeEventListener('scroll', this.caseScrollHandle)
    }
    caseScrollHandle() {
        const t = document.documentElement.scrollTop || document.body.scrollTop;
        if (t == 0) {
            this.setState({
                isTop: true
            })
        } else {
            this.setState({
                isTop: false
            })
        }
    }
    render() {
        let { topDis, showMore, isTop, activeIndex } = this.state

        return (
            <div className="answer_list_page">
                <div className="title">微运营</div>
                {
                    !isTop &&
                    <div className="question_item_wrap">
                        <ul className="question_list">
                          {
                            !showMore && <li className="question_item"><a href={`#operation-question-${activeIndex + 1}`} className="category-item-link">{questionList[activeIndex]}</a></li>
                          }
                          {showMore && 
                            <>
                              {questionList.map((element,index) => {
                                return  <li className="question_item" onClick={() => {
                                  this.setState({
                                    activeIndex: index,
                                    showMore: false,
                                    topDis: '-2.59rem',
                                  })
                                }}><a href={`#operation-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                              })}
                            </>
                          }
                        </ul>
                        <div className="show_more" onClick={() => {
                            this.setState({
                                showMore: !showMore
                            }, () => {
                                let { showMore } = this.state;
                                this.setState({
                                    topDis: showMore ? '-6.37rem' : '-2.59rem'
                                })
                            })
                        }}>{showMore ? '收起更多问题' : '展开更多问题'}</div>
                    </div>
                }
                {
                    isTop &&
                    <ul className="question_list">
                      {questionList.map((element,index) => {
                        return  <li className="question_item" onClick={() => {
                          this.setState({
                            activeIndex: index,
                            showMore: false,
                            topDis: '-2.59rem',
                          })
                        }}><a href={`#operation-question-${index + 1}`} className="category-item-link">{questionList[index]}</a></li>
                      })}

                    </ul>
                }

                <div className="answerList">
                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-1" />
                    <div className="solution-title">Q1：如何查看数据统计？</div>
                    <div className="solution-content">A：在后台—概览中即可查看近七天的订单趋势图和昨日浏览次数、昨日浏览人数、昨日下单笔数、待发货订单、昨日交易额等。</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q1-1.png')} />
                </div>

                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-2" />
                    <div className="solution-title">Q2：如何管理客户？</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q2-1.png')} />
                    <div className="solution-content">在“客户”——客户管理中，可以查看访问小程序的客户的微信列表，在列表中会展示微信昵称、头像、地区等基础信息，还可向客户进行充值/扣除储值金、赠送/扣除积分的操作。</div>

                </div>

                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-3" />
                    <div className="solution-title">Q3：如何管理会员？</div>
                    <div className="solution-content">A：</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q3-1.png')} />
                    <div className="solution-content">会员管理页面可以查看领取了会员卡的客户列表，列表中会有客户的姓名、手机号、会员卡号、消费和积分信息等，还可以手动新增或导入会员，对会员的积分、储值金、标签、分组、生日以及会员基本信息（姓名、电话号码）进行修改。</div>
                    <div className="solution-content">
                        <ul>
                            <li>• 添加标签：每个会员可添加多个标签，可以从标签库中选择标签标记会员，也可以直接新建标签到标签库中。注意，不能添加重复的标签</li>
                            <li><img className="helper-image" src={require('../../image/answerList/operation-q3-2.png')} /></li>
                            <li><img className="helper-image" src={require('../../image/answerList/operation-q3-3.png')} /></li>
                            <li>进入“待激活会员”页面，可手动新增会员或批量导入会员</li>
                            <li>• 新增会员：点击进入“添加会员”页面，填写会员信息后完成添加</li>
                            <li>• 导入会员：点击“导入会员”页面，可点击下载导入模板，根据导入模板添加客户数据，再点“批量导入客户”上传数据文件即可</li>
                        </ul>
                    </div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q3-4.png')} />
                    <div className="solution-content">积分明细页面可以查看领取了积分的客户列表，列表中会有客户微信头像昵称以及积分变化的情况等。</div>
                </div>

                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-4" />
                    <div className="solution-title">Q4：什么是分销？</div>
                    <div className="solution-content">A：通过分销管理，可以通过推广员快速推广小程序，以商品销售佣金激发用户裂变推广，实现平台低成本投入，高收益回报。平台支持 2 级分佣，无限级推广员的链式关系。</div>
                    <div className="solution-content">
                        <ul>
                            <li>• 直接推广员：购买顾客的直接上级推广员，如 b 通过推广员 a 的分享进行了购买，则a是b 的直接推广员，赚取 b 消费的直接推广佣金</li>
                            <li>• 间接推广员：购买顾客的直接推广员的上一级推广员，如 a 是 b 的直接推广员，b 又是c的直接推广员，那么 a 就是 c 的间接推广员。a 赚取b 消费的直接推广佣金和c 消费的间接推广佣金，b 赚取 c 消费的直接推广佣金</li>
                            <li>• 分销佣金：推广员的下级在小程序中进行消费，根据设置的分销规则，系统会自动给推广员奖励佣金。推广员可以在小程序的推广中心实时查看自己的活动的推广佣金。在确认收货后，佣金会自动结算。只有已结算的佣金可以提现</li>
                            <li>• 推广海报：成为推广员后，推广员可以在小程序中获取自己的专属推广海报。只要通过推广海报进入到小程序的新用户，都会自动成为该推广员的下级</li>
                            <li>• 分销佣金计算规格：商品价格×佣金比例</li>
                            <li>• 分销佣金发放规则：分销佣金&gt;顾客实际微信支付金额</li>
                        </ul>
                    </div>
                </div>

                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-5" />
                    <div className="solution-title">Q5：如何管理推广员？</div>
                    <div className="solution-content">A：</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q5-1.png')} />
                    <img className="helper-image" src={require('../../image/answerList/operation-q5-2.png')} />
                    <div className="solution-content">先使用分销开关控制小程序分销功能得开启，只有开启了分销功能之后，推广员才能进入推广中心查看和提现收益。同时可以设置推广员的加入门槛，若默认无门槛，则可设置单笔购物满一定金额才能成为推广员。</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q5-3.png')} />
                    <div className="solution-content">开启推广员审批后，小程序用户在提交推广员申请后，商家可以在商城后台-->分销-->推广员管理-->待审批中对申请的推广员进行审核。开启推广员审批后，商家可以在待审批中对申请的推广员进行审核，审批通过或被拒绝申请者都可收到模板消息提醒。</div>

                </div>

                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-6" />
                    <div className="solution-title">Q6：如何管理会员卡？</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q6-1.png')} />
                    <div className="solution-content">通过会员卡管理可以创建会员卡，买家可以领取会员卡，享受会员权益。</div>
                    <div className="solution-content">
                        <ul>
                            <li>• 新建会员卡：点击添加新的会员卡</li>
                            <li>• 查看会员：查看领取了该会员卡的会员信息</li>
                            <li>• 编辑：可修改会员卡的信息</li>
                            <li>• 发卡：可选择一个或若干个访问小程序的用户发放该会员卡</li>
                            <li>• 停用：停用此会员卡</li>
                            <li>• 复制路径：可获取当前会员卡的小程序领取页面路径</li>
                        </ul>
                    </div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q6-2.png')} />
                    <div className="solution-content">同时会员管理页面可以查看领取了会员卡的客户列表，列表中会有客户的姓名、手机号、会员卡号、消费和积分信息等。</div>
                </div>

                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-7" />
                    <div className="solution-title">Q7：会员储值如何使用？</div>
                    <div className="solution-content">A：</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q7-1.png')} />
                    <div className="solution-content">通过会员储值管理可以创建储值项目，买家可以自行选择不同的储值额度进行储值。</div>
                    <div className="solution-content">
                        <ul>
                            <li>• 新建储值项目：点击添加新的储值项目</li>
                            <li>• 编辑：可修改储值项目的信息</li>
                            <li>• 上架状态：取消勾选后，买家将不能选择该储值项目进行储值</li>
                        </ul>
                    </div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q7-2.png')} />
                    <div className="solution-content">储值概况页面可以查看储值账户的资产情况以及最近的收支明细等。</div>
                </div>

                <div className="solution-item">
                    <div style={{ position: 'absolute', top: topDis }} id="operation-question-8" />
                    <div className="solution-title">Q8：如何增加店铺复购率？</div>
                    <div className="solution-content">A：商家可通过微信公众号给粉丝发送图文消息，激活粉丝流量池，转化微信公众号粉丝，提高顾客入店率，增加店铺销售额。</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q3-1.png')} />
                    <div className="solution-content">同时通过对浏览但未下单的顾客针对性的发送消息或赠送优惠券，促使其再次访问并下单付款。对长期未复购的顾客同样发送消息或优惠券，促使再次消费，增加店铺复购率。</div>
                    <img className="helper-image" src={require('../../image/answerList/operation-q8-2.png')} />
                </div>
            </div>
            </div>
            )
    }
}