import React from 'react'



export default class ClickPop extends React.Component {


    render() {
        let { closeFun } = this.props
        return (
            <div className='clickpage'>
                <div className='clicktitle'>请用电脑访问<a style={{ color:'#0099FC'}}>panshi101.com</a>免费试用</div>
                <div className='clickok' onClick={() => {
                    closeFun && closeFun()
                }}>知道了</div>


         </div>

        )
    }


}