import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import TitleCmp from '../../components/TitleCmp'
import { client } from '../../util/fontsize'
import url from '../../util/path'
let clientInfo = client()

const marketingData1 = [
  {
    iconSrc: url+'micromarketing/get_customer_item_1.png',
    title: '分销员',
    content: '裂变传播，让更多人帮你引流',
    key: 1,
    background: 'rgba(252,250,246,1)'
  },
  {
    iconSrc: url+'micromarketing/get_customer_item_2.png',
    title: '门店定位',
    content: '根据客户位置，精准定位门店',
    key: 2,
    background: 'rgba(249,253,255,1)'
  },
  {
    iconSrc: url+'micromarketing/get_customer_item_3.png',
    title: '推广海报',
    content: '一件生成假日活动推广海报',
    key: 3,
    background: 'rgba(248,253,255,1)'
  },
  {
    iconSrc: url+'micromarketing/get_customer_item_4.png',
    title: '咻一咻',
    content: '咻一咻抢福利，支付宝经典玩法',
    key: 4,
    background: 'rgba(245,252,250,1)'
  },
  {
    iconSrc: url+'micromarketing/get_customer_item_5.png',
    title: '刮刮卡',
    content: '传说中男女老少通吃就是他',
    key: 5,
    background: 'rgba(253,250,250,1)'
  },
  {
    iconSrc: url+'micromarketing/get_customer_item_6.png',
    title: '大转盘',
    content: '转盘式抽奖玩法',
    key: 6,
    background: 'rgba(250,251,255,1)'
  },
  {
    iconSrc: url+'micromarketing/get_customer_item_7.png',
    title: '砸金蛋',
    content: '好运砸出来',
    key: 7,
    background: 'rgba(255,252,243,1)'
  },
  {
    iconSrc: url+'micromarketing/get_customer_item_8.png',
    title: '摇钱树',
    content: '摇一摇，红包摇出来',
    key: 8,
    background: 'rgba(249,253,255,1)'
  },
];

const marketingData2 = [
  {
    iconSrc: url+'micromarketing/transfer_item_1.png',
    title: '优惠券',
    content: '店铺必备，营销神器',
    key: 1,
  },
  {
    iconSrc: url+'micromarketing/transfer_item_2.png',
    title: '秒杀活动',
    content: '倒计时限时秒杀',
    key: 2,
  },
  {
    iconSrc: url+'micromarketing/transfer_item_3.png',
    title: '会员折扣',
    content: '会员专属折扣力度',
    key: 3,
  },
  {
    iconSrc: url+'micromarketing/transfer_item_4.png',
    title: '积分抵扣',
    content: '积分可抵扣金额',
    key: 4,
  },
  {
    iconSrc: url+'micromarketing/transfer_item_5.png',
    title: '满包邮',
    content: '购满一定金额享包邮',
    key: 5,
  },
  {
    iconSrc: url+'micromarketing/transfer_item_6.png',
    title: '满减',
    content: '购满一定金额或件数享受优惠',
    key: 6,
  },
  {
    iconSrc: url+'micromarketing/transfer_item_7.png',
    title: '会员次卡',
    content: '线上储值，线下消费',
    key: 7,
  }
];

const marketingData3 = [
  {
    iconSrc: url+'micromarketing/fission_item_1.png',
    title: '全民拼团',
    content: '店铺必备，营销神器',
    key: 1,
    background:'rgba(253,250,250,1)'
  },
  {
    iconSrc: url+'micromarketing/fission_item_2.png',
    title: '新版拼团',
    content: '倒计时限时秒杀',
    key: 2,
    background: 'rgba(255,250,248,1)'
  },
  {
    iconSrc: url+'micromarketing/fission_item_3.png',
    title: '阶梯拼团',
    content: '会员专属折扣力度',
    key: 3,
    background: 'rgba(255,251,246,1)'
  },
  {
    iconSrc: url+'micromarketing/fission_item_4.png',
    title: '疯狂砍价',
    content: '亲朋好友一起砍',
    key: 4,
    background: 'rgba(255,249,251,1)'
  },
];

const marketingData4 = [
  {
    iconSrc: url+'micromarketing/rebuy_item_1.png',
    title: '会员储值',
    content: '吸引客户储值，带来持续付费',
    key: 1,
  },
  {
    iconSrc: url+'micromarketing/rebuy_item_2.png',
    title: '积分体系',
    content: '提升客户活跃，提高忠诚度',
    key: 2,
  },
  {
    iconSrc: url+'micromarketing/rebuy_item_3.png',
    title: '会员卡',
    content: '发放会员卡，提高客户粘性',
    key: 3,
  },
];

export default class MicroMarketing extends PureComponent {
  

    constructor(props) {
      super(props)
      this.state = {
        current: 'guest',
        isActive: false,
        scrollDec: 0,
      }
      this.bannerRef = React.createRef();
      this.marketingScrollHandle = this.marketingScrollHandle.bind(this);
    }
    componentDidMount() {
      document.getElementById("root").classList.remove("root_box");
      window.addEventListener('scroll', this.marketingScrollHandle);
      this.topRef && this.topRef.current && this.topRef.current.scrollIntoView();
    }
    componentWillUnmount() {
      document.getElementById("root").classList.add("root_box");
      window.removeEventListener('scroll', this.marketingScrollHandle)
    }
    marketingScrollHandle() {
      let that = this;
      let { scrollDec } = that.state;
      let _width = clientInfo.width
      let curBanH = _width * 430 / 750
      let topH = _width * 89 / 750
      // let dis1 = _width * 1153 / 750
      // let dis2 = _width * 1834 / 750
      // let dis3 = _width * 2524 / 750
      let dis1 = _width * 950 / 750
      let dis2 = _width * 1600 / 750
      let dis3 = _width * 1900 / 750
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      if (that.bannerRef && that.bannerRef.current) {
        const bannerH = that.bannerRef.current.offsetHeight;
        if(scrollDec > t) {
         //console.log('向上滚动了')
        }
        if (t >= bannerH - topH) {
          that.setState({
            isActive: true,
            scrollDec: t,
            isUp: scrollDec > t
          });
        } else {
          that.setState({
            isActive: false,
            scrollDec: t,
            isUp: scrollDec > t
          });
        }
      }
      // 监听滚动条 修改状态
      if (t > dis1) {
        that.setState({
          current: 'conversion'
        })
      }
      if (t > dis2) {
        that.setState({
          current: 'fission'
        })
      }
      if (t > dis3) {
        that.setState({
          current: 'repurchase'
        })
      }
      if(t < dis1) {
        that.setState({
          current: 'guest'
        })
      }
    }
    handleClick = selectKey => {
      this.setState({
        current: selectKey,
      }, () => {
        this.scrollToAnchor(this.state.current)
      });
    };

    scrollToAnchor = anchorName => {
      if (anchorName) {
        const anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
          anchorElement.scrollIntoView();
        }
      }
    };

    getIconItem = (ele, index) => {
      return (
        <div className={(index + 1) % 3 == 0 ? "icon_item no_margin_right" : "icon_item" }>
          <img src={ele.iconSrc} />
          <div>{ele.title}</div>
         </div>
      )
    }
    getIconItem2 = (ele, index) => {
      return (
        <div className={(index + 1) % 3 == 0 ? "icon_item icon_item_2 no_margin_right" : "icon_item icon_item_2" }>
          <img src={ele.iconSrc} />
          <div className="title">{ele.title}</div>
          <div className="sub_title">{ele.content}</div>
         </div>
      )
    }
    portalTab = () => {
      let { current, isActive, isUp } = this.state
     let tab_cmp = (
      <div className="tab_wrap_new" style={isActive ? {position: 'fixed', top: 0,left: 0, right: 0, zIndex: 999, background: '#f8f8f8'} : {}}>
        <div key="guest" className={current === "guest" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('guest')}>获客</div>
        <div key="conversion" className={current === "conversion" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('conversion')}>转化</div>
        <div key="fission" className={current === "fission" ? "tab_item tab_item_active" : "tab_item"}  onClick={() => this.handleClick('fission')}>裂变</div>
        <div key="repurchase" className={current === "repurchase" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('repurchase')}>复购</div>
      </div>
     )
     return  ReactDOM.createPortal(
        tab_cmp,
        document.getElementById('root')
      );
    }
    
    render() {
      let { current, isActive, isUp } = this.state
      return (
        <div className="micro_marketing_page">
          <div className="micromarketingpage" ref={this.bannerRef}></div>
          {
            isActive ? this.portalTab()
            :
            <div className="tab_wrap" >
              <div key="guest" className={current === "guest" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('guest')}>获客</div>
              <div key="conversion" className={current === "conversion" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('conversion')}>转化</div>
              <div key="fission" className={current === "fission" ? "tab_item tab_item_active" : "tab_item"}  onClick={() => this.handleClick('fission')}>裂变</div>
              <div key="repurchase" className={current === "repurchase" ? "tab_item tab_item_active" : "tab_item"} onClick={() => this.handleClick('repurchase')}>复购</div>
            </div>
          }
          <div className="hk_section" >
            <div style={{ position: 'absolute', top: '-1.6rem' }} id="guest" />
            <TitleCmp title="获客拉新，更多精准新用户" />
            <div className="icon_list" style={{ marginTop: '0.44rem', marginBottom: '0.4rem' }}>
              {
                marketingData1.map((element, index) => {
                  return this.getIconItem(element, index)
                })
              }
            </div>
          </div>
          <div className="zh_section" >
            <div style={{ position: 'absolute', top: '-1.6rem' }} id="conversion" />
            <TitleCmp title="下单转化 提升订单和销量"/>
            <div className="icon_list" style={{ marginTop: '0.44rem', marginBottom: '0.4rem' }}>
              {
                marketingData2.map((element, index) => {
                  return this.getIconItem(element, index)
                })
              }
            </div>
          </div>
          <div className="lb_section" >
            <div style={{ position: 'absolute', top: '-1.6rem' }} id="fission" />
            <TitleCmp title="裂变增长 利用老客户获取新客户"/>
            <div className="icon_list" style={{ marginTop: '0.44rem', marginBottom: '0.4rem' }}>
              {
                marketingData3.map((element, index) => {
                  return this.getIconItem2(element, index)
                })
              }
            </div>
          </div>
          <div className="fg_section" >
            <div style={{ position: 'absolute', top: '-1.6rem' }} id="repurchase" />
            <TitleCmp title="留存复购 维护老客不流失"/>
            <div className="icon_list" style={{ marginTop: '0.44rem', marginBottom: '0.4rem' }}>
              {
                marketingData4.map((element, index) => {
                  return this.getIconItem2(element, index)
                })
              }
            </div>
          </div>
        </div>

        )
    }

}