import React from 'react'

export default class WxCodeCmp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      wxcodeActive: false
    };
  }
  render() {
    let { wxcodeActive } = this.state 
    let { data, key, isLast } = this.props
    return (
      <div className="wxcode_cmp" style={isLast ? { marginRight: '0.38rem'} : {}}>
        <div className="wxcode_info_wrap">
          <img src={data.graphic} style={{ width: '5.2rem', height: '3.2rem' }} />
          <div className="wxcode_content">
            <div className="wxcode_title">{data.title}</div>
            <div className="wxcode_desc">{data.content}</div>
          </div>
          <div className="wxcode_sm_img_wrap" onClick={() => {
            this.setState({ 
              wxcodeActive: true
            })
          }}>
            <img style={{ width: '0.97rem' }} src={data.graphic1} alt="" />
            <div className="tip_wrap">点击扫码</div>
          </div>
        </div>
        {
          wxcodeActive &&
          <div className="wxcode_wrap" onClick={() => {
            this.setState({
              wxcodeActive: false
            })
          }}>
            <img className="wxcode_big_img" src={data.graphic1} alt="" />
          </div>
        }
      </div>
    )
  }
}