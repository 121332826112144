import React, { Component } from "react";
import { List } from 'antd-mobile';

const Item = List.Item;


export default class HelpCenter extends Component {
    // constructor(props) {
    //     super(props)
    //     this.topRef = React.createRef();

    // }
    // componentDidMount() {
    //     this.topRef && this.topRef.current.scrollIntoView();

    // }
    render() {
        let { history } = this.props
        
        return (

            <div className='helpCenterpage' >
                <div className='helpCenterImg'></div>
                <div className='helpCenterList'>
                    <List className="my-list" >
                        <Item arrow="horizontal" multipleLine onClick={() => {
                          history && history.push('/answerListOne')
                         }}>
                            新手帮助
                        </Item>
                    </List>
                    <List className="my-list">
                        <Item arrow="horizontal" multipleLine onClick={() => {
                            history && history.push('/SocialMarketing')
                         }}>
                            微营销
                        </Item>
                    </List>
                    <List className="my-list">
                        <Item arrow="horizontal" multipleLine onClick={() => {
                            history && history.push('/SocialOperation')
                         }}>
                            微运营
                        </Item>
                    </List>
                    <List className="my-list">
                        <Item arrow="horizontal" multipleLine onClick={() => {
                            history && history.push('/SocialActivity')
                            
                         }}>
                            微商城
                        </Item>
                    </List>
                    <List className="my-list">
                        <Item arrow="horizontal" multipleLine onClick={() => {
                            history && history.push('/SocialFlow')
                         }}>
                            微流量
                        </Item>
                    </List>
                </div>



            </div>
        )
    
    
    }

}
